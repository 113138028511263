import { Box, Grid, Stack, Typography, Container } from '@mui/material';
import VideoPreviewThumbnail from 'components/VideoPreviewThumbnail';
import VideoPublishThumbnail from 'components/VideoPublishThumbnail';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { pluralize } from 'utils/formatString';
import { VideoIcon } from 'components/icon/DashboardIcon';

export default function AppPublish(props) {
  const { handleOpenVideoModal, videos } = props;
  const { previewList, publishList } = useSelector((state) => state.videoListSlice);
  const [publishShow, setPublishShow] = useState([]);

  useEffect(() => {
    let sectionOne = [];
    let sectionTwo = [];

    for (let i = 0; i < publishList.length; i += 1) {
      if (publishList[i].section === 'Section 1') {
        sectionOne = [publishList[i]];
      }
      if (publishList[i].section === 'Section 2') {
        sectionTwo = [publishList[i]];
      }
    }
    const temp = sectionOne.concat(sectionTwo);
    setPublishShow(temp);
  }, [publishList]);

  return (
    <Container maxWidth={false}>
      {previewList.length > 0 || publishList.length > 0 ? (
        <>
          <Box>
            <Grid sx={{ paddingBottom: '20px' }}>
              <Typography variant="subtitle1">Published Videos</Typography>
            </Grid>
            <Grid container spacing={2}>
              {publishShow.length === 0 && (
                <Grid item>
                  <Typography variant="body2">No publish video found.</Typography>
                </Grid>
              )}
              {publishShow.map((item, index) => (
                <Grid item key={index}>
                  <VideoPublishThumbnail
                    section={item.section}
                    name={item.name}
                    location={item.location}
                    date={item.date}
                    image={item.thumbnail}
                    handleOpenVideoModal={handleOpenVideoModal}
                    id={item.id}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{ mt: '20px' }}>
            <Grid sx={{ paddingBottom: '20px' }}>
              <Typography variant="subtitle1">
                Favourite ({previewList.filter((item) => item.favourite === true).length}{' '}
                {pluralize(previewList.filter((item) => item.favourite === true).length, 'Video')})
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              {previewList.map(
                (item, index) =>
                  item.favourite && (
                    <Grid item key={index}>
                      <VideoPreviewThumbnail
                        showPublish
                        id={item.id}
                        name={item.name}
                        amount={item.amount}
                        location={item.location}
                        date={item.date}
                        image={item.thumbnail}
                        favourite={item.favourite}
                        publish={item.publish}
                        sessionId={item.sessionId}
                        handleOpenVideoModal={handleOpenVideoModal}
                      />
                    </Grid>
                  )
              )}
            </Grid>
          </Box>
        </>
      ) : (
        <Grid item width="100%" justifyContent="center">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ mt: 5 }}
          >
            <VideoIcon width={51} height={43} color="#919EAB" />
            <Typography variant="body2">
              Video for this specie has not yet been selected for publishing
            </Typography>
          </Stack>
        </Grid>
      )}
    </Container>
  );
}
