// material
import {
  Box,
  Grid,
  Container,
  Tabs,
  Tab,
  Card,
  CardContent,
  Divider,
  styled,
  alpha,
  Modal,
  Paper,
  Typography,
  Stack,
  CircularProgress
} from '@mui/material';
// components
import { useEffect, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import Page from 'components/Page';
import Iconify from 'components/Iconify';
import AppNumberTypeAmount from 'sections/@dashboard/app/AppNumberTypeAmount';
import AppTypePieCharts from 'sections/@dashboard/app/AppTypePieCharts';
import AppConfidenceChart from 'sections/@dashboard/app/AppConfidentChart';
import AppVideoSuggestion from 'sections/@dashboard/app/AppVideoSuggestion';
import AppClassifiedChart from 'sections/@dashboard/app/AppClassifiedChart';
import FilterBar from 'sections/@dashboard/app/FilterBar';
import { addDays, format } from 'date-fns';
import { fDateSDmyToDYmd } from 'utils/formatTime';
import { ApiGetProcessingObjects, ApiGetReport, ApiGetSessions } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { Top30Icon, VideoSuggestionIcon } from 'components/icon/DashboardIcon';
import AppPublish from 'sections/@dashboard/app/AppPublish';
import AppPreviewVideo from 'sections/@dashboard/app/AppPreviewVideo';
import {
  setNewVideoPreviewList,
  setNewVideoPublishList,
  updateUsePreviewList
} from 'sections/@dashboard/app/videoListSlice';

// ----------------------------------------------------------------------
const DRAWER_WIDTH = 270;

const FilterBarFixedStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  zIndex: 1000,
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  width: `calc(100% - ${DRAWER_WIDTH}px)`,
  [theme.breakpoints.down('lg')]: {
    width: `95%`
  },
  borderBottom: `1px solid rgba(145, 158, 171, 0.32)`
}));

const CardShadowStyle = styled(Card)(({ theme }) => ({
  boxShadow: '0px 16px 32px -4px rgba(145, 158, 171, 0.12)'
}));

const PaperModal = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  border: '1px solid rgba(145, 158, 171, 0.32)',
  borderRadius: '0px',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}));

const paperCoverStyle = {
  backgroundColor: '#fff',
  opacity: '0.3',
  zIndex: 10000,
  filter: 'blur(2px)'
};

const BoxStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  position: 'absolute',
  top: '50%',
  left: `calc(50% + ${DRAWER_WIDTH / 2}px)`,
  [theme.breakpoints.down('lg')]: {
    left: `50%`
  },
  transform: 'translate(-50%, -50%)'
}));

export default function DashboardApp() {
  const [tab, setTab] = useState('Video suggestion');
  const [isScroll, setIsScroll] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [title, setTitle] = useState('Dashboard');
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(format(addDays(new Date(), -30), 'd/M/yyyy'));
  const [endDate, setEndDate] = useState(format(new Date(), 'd/M/yyyy'));
  const [type, setType] = useState('All types');
  const [typeId, setTypeId] = useState();
  const [location, setLocation] = useState('All locations');
  const [locationId, setLocationId] = useState();
  const [confidence, setConfidence] = useState('All confidences');
  const [confidenceId, setConfidenceId] = useState('Any');
  const [isDrillDown, setIsDrillDown] = useState(false);
  const [queryFullParam, setQueryFullParam] = useState('');
  const [isZero, setIsZero] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const { previewList, publishList } = useSelector((state) => state.videoListSlice);

  // 1st section
  const [fishNum, setFishNum] = useState(0);
  const [typeTotal, setTypeTotal] = useState(0);

  // 2nd section
  const [typeList, setTypeList] = useState([]);

  // 3rd section
  const [avgConfidence, setAvgConfidence] = useState(0);
  const [classified, setClassified] = useState(0);
  const [numberByDate, setNumberByDate] = useState([]);

  // Video modal
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const handleOpenVideoModal = () => setOpenVideoModal(true);
  const handleCloseVideoModal = () => setOpenVideoModal(false);

  const [time, setTime] = useState('Any time');
  const [timeId, setTimeId] = useState('Any');

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const controlFilterBar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > 0) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
      setLastScrollY(window.scrollY);
    }
  };

  const settingVideoPreviewList = async (query) => {
    setIsVideoLoading(true);
    dispatch(setNewVideoPreviewList({ previewList: [] }));

    const result = await ApiGetProcessingObjects(query);

    const previewList = [];
    result.data.data.forEach((item) => {
      previewList.push({
        id: item.id,
        name: item.name,
        amount: item.num_fish,
        location: item.location.name,
        date: item.meta.video_date,
        thumbnail: item.thumbnail,
        favourite: item.favourite,
        publish: item.publish,
        session_id: item.session_id
      });
    });
    setVideos(previewList);
    dispatch(setNewVideoPreviewList({ previewList }));
    setIsVideoLoading(false);
  };
  const settingVideoPublishList = async (query) => {
    let sections = [];
    await ApiGetSessions()
      .then((response) => {
        sections = response.data.data;
      })
      .catch((err) => console.error(err));

    ApiGetProcessingObjects(query).then(async (response) => {
      const publishList = [];
      response.data.data.map((item) => {
        const section = sections.find((x) => x.id === item.session_id);
        if (section) {
          publishList.push({
            id: item.id,
            name: item.name,
            amount: item.num_fish,
            location: item.location.name,
            date: item.meta.video_date,
            thumbnail: item.thumbnail,
            favourite: item.favourite,
            publish: item.publish,
            session_id: item.session_id,
            section: section.name
          });
        }
        return 'success';
      });
      dispatch(setNewVideoPublishList({ publishList }));
    });
  };

  useEffect(() => {
    let query = `?filter.object_table_type=$in:analysis&filter.meta.specie_id=${typeId}&sortBy=num_fish:DESC`;
    switch (tab) {
      case 'Video suggestion':
        setVideos([]);
        query += `&filter.meta.video_date=${fDateSDmyToDYmd(startDate)},${fDateSDmyToDYmd(
          endDate
        )}`;
        if (locationId !== undefined && locationId !== 'All') {
          query += `&filter.meta.location_id=${locationId}`;
        }
        if (confidenceId !== undefined && confidenceId !== 'Any') {
          query += `&filter.meta.avg_confidence=${confidenceId}`;
        }
        if (timeId !== undefined && timeId !== 'Any') {
          query += `&filter.meta.time=${timeId}`;
        }
        query += '&limit=3';
        settingVideoPreviewList(query);
        // dispatch(updateUsePreviewList(true));
        break;
      case 'Top30':
        setVideos([]);
        query += `&filter.meta.video_date=${fDateSDmyToDYmd(startDate)},${fDateSDmyToDYmd(
          endDate
        )}`;
        if (locationId !== undefined && locationId !== 'All') {
          query += `&filter.meta.location_id=${locationId}`;
        }
        if (confidenceId !== undefined && confidenceId !== 'Any') {
          query += `&filter.meta.avg_confidence=${confidenceId}`;
        }
        if (timeId !== undefined && timeId !== 'Any') {
          query += `&filter.meta.time=${timeId}`;
        }
        query += '&limit=30';
        settingVideoPreviewList(query);
        // dispatch(updateUsePreviewList(true));
        break;
      case 'Publish':
        // setVideos([]);
        settingVideoPreviewList(`${query}&filter.favourite=$eq:true`);
        settingVideoPublishList(`${query}&filter.publish=$eq:true`);
        // dispatch(updateUsePreviewList(true));
        break;
      default:
    }
  }, [tab, startDate, endDate, type, location, confidence, time]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlFilterBar);
      return () => {
        window.removeEventListener('scroll', controlFilterBar);
      };
    }
  }, [lastScrollY]);

  useEffect(() => {
    setLoading(true);
    let queryParam = `?dateFrom=${fDateSDmyToDYmd(startDate)}&`;
    queryParam = `${queryParam}dateTo=${fDateSDmyToDYmd(endDate)}&`;
    if (type !== 'All types') {
      queryParam = `${queryParam}type=${typeId}&`;
    }
    if (location !== 'All locations') {
      queryParam = `${queryParam}location=${locationId}&`;
    }
    if (!['All confidences'].includes(confidence)) {
      queryParam = `${queryParam}confidence=${confidenceId}&`;
    }
    if (timeId !== 'Any') {
      queryParam = `${queryParam}time=${timeId}&`;
    }
    queryParam = queryParam.substring(0, queryParam.length - 1);
    setQueryFullParam(queryParam);
    ApiGetReport(queryParam).then((response) => {
      setFishNum(type === 'All types' ? response.data.data.total : response.data.data.classified);
      setTypeTotal(response.data.data.type_length);
      if (response.data.data.type_all) {
        setTypeList(response.data.data.type_all);
      }
      setAvgConfidence(
        response.data.data.avg_confidence ? response.data.data.avg_confidence.toFixed(2) : 0
      );
      setClassified(response.data.data.classified);
      setNumberByDate(response.data.data.date);
      // eslint-disable-next-line no-unused-expressions
      response.data.data.total === 0 ? setIsZero(true) : setIsZero(false);
      setLoading(false);
    });
  }, [startDate, endDate, type, location, confidence, time]);

  useEffect(() => {
    if (type !== 'All types') {
      setIsDrillDown(true);
    } else {
      setIsDrillDown(false);
    }
  }, [type]);

  return (
    <Page title="Dashboard | Aquatic monitoring system">
      <Container sx={{ marginLeft: 0 }} maxWidth={false}>
        <FilterBarFixedStyle
          className={`${!isScroll && 'hidden'}`}
          style={{ paddingTop: isScroll && '25px' }}
        >
          <FilterBar
            type={type}
            typeId={typeId}
            setType={setType}
            setTypeId={setTypeId}
            location={location}
            locationId={locationId}
            setLocation={setLocation}
            setLocationId={setLocationId}
            confidence={confidence}
            confidenceId={confidenceId}
            setConfidence={setConfidence}
            setConfidenceId={setConfidenceId}
            isFixed
            title={title}
            setTitle={setTitle}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            queryFullParam={queryFullParam}
            isZero={isZero}
            time={time}
            timeId={timeId}
            setTime={setTime}
            setTimeId={setTimeId}
          />
        </FilterBarFixedStyle>

        <Box sx={{ visibility: `${isScroll && 'hidden'}` }}>
          <FilterBar
            type={type}
            typeId={typeId}
            setType={setType}
            setTypeId={setTypeId}
            location={location}
            locationId={locationId}
            setLocation={setLocation}
            setLocationId={setLocationId}
            confidence={confidence}
            confidenceId={confidenceId}
            setConfidence={setConfidence}
            setConfidenceId={setConfidenceId}
            isFixed={false}
            title={title}
            setTitle={setTitle}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            queryFullParam={queryFullParam}
            isZero={isZero}
            time={time}
            timeId={timeId}
            setTime={setTime}
            setTimeId={setTimeId}
          />
        </Box>
        <Paper
          sx={{
            ...((isZero || loading) && paperCoverStyle)
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <CardShadowStyle>
                <CardContent>
                  <AppNumberTypeAmount fishNum={fishNum} typeNum={typeTotal} />
                </CardContent>
              </CardShadowStyle>
            </Grid>

            <Grid item xs={12} md={8} lg={8}>
              <CardShadowStyle sx={{ height: '480px' }}>
                <AppTypePieCharts
                  drillDown={setIsDrillDown}
                  type={type}
                  setType={setType}
                  typeList={typeList}
                  setTypeId={setTypeId}
                />
              </CardShadowStyle>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <CardShadowStyle sx={{ height: '480px' }}>
                <AppConfidenceChart avgConfidence={avgConfidence} />
                <Divider variant="middle" />
                <AppClassifiedChart
                  isDrillDown={isDrillDown}
                  classified={classified}
                  total={fishNum}
                  numberByDate={numberByDate}
                />
              </CardShadowStyle>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ visibility: isDrillDown ? 'visible' : 'hidden' }}
            >
              <Box sx={{ width: '100%', typography: 'body1', minHeight: '700px' }}>
                <TabContext value={tab}>
                  <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    aria-label="report export"
                    textColor="inherit"
                  >
                    <Tab
                      icon={<VideoSuggestionIcon width={30} />}
                      iconPosition="start"
                      label="Video suggestion"
                      value="Video suggestion"
                      sx={{ textTransform: 'unset' }}
                    />
                    <Tab
                      icon={<Top30Icon width={30} />}
                      iconPosition="start"
                      label="Top30"
                      value="Top30"
                    />
                    <Tab
                      icon={<Iconify icon="bi:share-fill" />}
                      iconPosition="start"
                      label="Publish"
                      value="Publish"
                    />
                  </Tabs>
                  <TabPanel value="Video suggestion">
                    <AppVideoSuggestion
                      handleOpenVideoModal={handleOpenVideoModal}
                      videos={videos}
                      isLoading={isVideoLoading}
                    />
                  </TabPanel>
                  <TabPanel value="Top30">
                    <AppPreviewVideo
                      handleOpenVideoModal={handleOpenVideoModal}
                      handleCloseVideoModal={handleCloseVideoModal}
                      isModal={false}
                      videos={videos}
                      isLoading={isVideoLoading}
                    />
                  </TabPanel>
                  <TabPanel value="Publish">
                    <AppPublish
                      handleOpenVideoModal={handleOpenVideoModal}
                      videos={videos}
                      isLoading={isVideoLoading}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <BoxStyle className={`${!isZero && !loading && 'hidden'}`}>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Typography variant="subtitle1">There is no data during this time.</Typography>
                <Typography variant="subtitle1">Please change the filter to view data.</Typography>
              </>
            )}
          </Stack>
        </BoxStyle>
      </Container>
      <Modal open={openVideoModal} onClose={handleCloseVideoModal} disableEscapeKeyDown>
        <PaperModal>
          <AppPreviewVideo
            handleOpenVideoModal={handleOpenVideoModal}
            handleCloseVideoModal={handleCloseVideoModal}
            isModal
          />
        </PaperModal>
      </Modal>
    </Page>
  );
}
