// material
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  styled,
  Tooltip,
  tooltipClasses,
  Stack
} from '@mui/material';
// utils
import { fNumber } from 'utils/formatNumber';
// component
import { DiagramIcon, FishIcon } from 'components/icon/DashboardIcon';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark
}));

const TypographyBottomStyle = styled(Typography)(({ theme }) => ({
  fontSize: '14px'
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}));

// ----------------------------------------------------------------------

export default function AppNumberTypeAmount({ fishNum, typeNum }) {
  return (
    <>
      <RootStyle>
        <Grid container>
          <Grid item xs>
            <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <IconWrapperStyle>
                    <FishIcon width={80} height={80} color="#2AAAE2" />
                  </IconWrapperStyle>
                </Grid>
                <Grid item align="left">
                  <BootstrapTooltip title="Estimated by our AI model">
                    <Typography variant="h3">
                      {fNumber(fishNum)} <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </BootstrapTooltip>
                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    Number of aquatic animals
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs>
            <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <IconWrapperStyle>
                    <DiagramIcon width={80} color="#2AAAE2" />
                  </IconWrapperStyle>
                </Grid>
                <Grid item align="left">
                  <Typography variant="h3">{fNumber(typeNum)}</Typography>
                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    Detected types
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </RootStyle>
      <Grid container>
        <Grid item xs>
          <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <TypographyBottomStyle>
                  <span style={{ color: 'red' }}>*</span> Estimated by our AI model
                </TypographyBottomStyle>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>
    </>
  );
}
