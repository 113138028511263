/* eslint-disable */
import Axios from 'axios';

import intance from './interceptor.js';

export const ApiGetLocations = async () => {
  return await intance.get('/locations');
};

export const ApiNewLocation = async ({ name }) => {
  return await intance.post('/locations', { name }).catch((err) => console.error(err));
};

export const ApiUpdateLocation = async (id, { name }) => {
  return await intance.patch('/locations/' + id, { name }).catch((err) => console.error(err));
};

export const ApiDeleteLocation = async (id) => {
  return await intance.delete('/locations/' + id).catch((err) => console.error(err));
};

export const ApiGetAuthDetail = async () => {
  return await intance.get('/auth');
};

export const ApiUpdateAuthDetail = async (body) => {
  return await intance.patch('/auth', body);
};

export const ApiGetProcessingObjects = async (query = '') => {
  return await intance.get('/objects' + query);
};

export const ApiGetOneObject = async (id) => {
  return await intance.get('/objects/' + id);
};

export const ApiNewUploadId = async ({ locationId }) => {
  return await intance.post('/uploads', { locationId }).catch((err) => console.error(err));
};

export const ApiGetPresignedUpload = async ({ uploadId, path, filename }) => {
  return await intance
    .get(`/uploads/${uploadId}/presigned?path=${path}&filename=${filename}`)
    .catch((err) => console.error(err));
};

export const ApiGetReport = async (query = '') => {
  return await intance.get('/reports' + query);
};

export const ApiGetReportDateRange = async (query = '') => {
  return await intance.get('/reports/date');
};

export const ApiGetSpecies = async () => {
  return await intance.get('/species');
};

export const ApiGetReportFile = async (query = '') => {
  return await intance.get('/reports/export' + query);
};

export const ApiUpdateObjectFavourite = async (id, value = false) => {
  return await intance.patch('/objects/' + id, { favourite: value });
};

export const ApiUpdateObject = async (id, body) => {
  return await intance.patch(`/objects/${id}`, body);
};

export const ApiUpdateObjectPublish = async (id, session_id, publish, favourite = null) => {
  let data = { session_id: session_id, publish: publish };
  if (favourite) {
    data = { session_id: session_id, publish: publish, favourite };
  }
  return await intance.patch('/objects/' + id, data);
};

export const ApiGetSessions = async () => {
  return await intance.get('/sessions');
};

export const ApiGetFilterAvailableDate = async (date_from, date_to) => {
  return await intance.get('/filters/date?dateFrom=' + date_from + '&dateTo=' + date_to);
};

export const ApiGetFilterAvailableData = async (query) => {
  return await intance.get('/filters' + query);
};

export const ApiUploadToS3 = async (signedUrl, file, percentCompleted, controller) => {
  const config = {
    headers: {
      'Content-Type': file.type || 'application/octet-stream'
    },
    signal: controller.signal
  };

  if (percentCompleted) {
    config.onUploadProgress = (progressEvent) => {
      percentCompleted(Math.floor((progressEvent.loaded * 100) / progressEvent.total));
    };
  }
  return await Axios.put(signedUrl, file, config);
};

export const APIAnalysisML = async (object_id) => {
  return await intance.post(`/objects/${object_id}/analysis`);
};
