import React, { useState, useEffect } from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ApiUpdateObjectFavourite } from 'api';
import {
  updatePreviewListFavourite,
  updatePublishListFavourite
} from 'sections/@dashboard/app/videoListSlice';
import Iconify from './Iconify';

const TypographyStyled = styled(Typography)({
  fontSize: '14px',
  color: '#637381'
});

export default function FavouriteIconRender({ id, value, size = null, withText = false }) {
  const { previewList, publishList, usePreviewList } = useSelector((state) => state.videoListSlice);
  const dispatch = useDispatch();
  const listRender = usePreviewList ? previewList : publishList;
  const [favorite, setFavorite] = useState(value);

  const handleOnClick = async (id) => {
    const objVal = listRender.find((x) => x.id === id).favourite;
    setFavorite(!objVal);
    await ApiUpdateObjectFavourite(id, !objVal);

    dispatch(updatePreviewListFavourite({ id, favourite: !objVal }));
    dispatch(updatePublishListFavourite({ id, favourite: !objVal }));
  };

  useEffect(() => {
    setFavorite(value);
  }, [value]);

  // eslint-disable-next-line react/no-unstable-nested-components
  function HeartIcon() {
    return (
      <Iconify
        icon={favorite === false ? 'codicon:heart' : 'codicon:heart-filled'}
        sx={{ mr: 1, color: favorite === true && 'red' }}
        width={size && size}
        height={size && size}
      />
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {withText ? (
        <Stack direction="row" onClick={() => handleOnClick(id)} sx={{ cursor: 'pointer' }}>
          <HeartIcon />
          <TypographyStyled variant="body2" sx={{ mb: 1 }}>
            Favourite
          </TypographyStyled>
        </Stack>
      ) : (
        <Box onClick={() => handleOnClick(id)} sx={{ cursor: 'pointer' }}>
          <HeartIcon />
        </Box>
      )}
    </>
  );
}
