import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Link, Container, Typography, styled } from '@mui/material';

// components
import Page from 'components/Page';
import Iconify from 'components/Iconify';
import { ConfirmationCodeForm } from 'sections/authentication/confirmationCode';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '10vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ProfileConfirmationCode() {
  return (
    <Page title="Code | Aquatic monitoring system">
      <Container sx={{ marginLeft: 0 }}>
        <Box sx={{ mb: 2 }}>
          <Link variant="string" underline="none" component={RouterLink} to="/dashboard/profile">
            <Iconify icon="eva:arrow-ios-back-fill" />
            Back
          </Link>
        </Box>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Please check your email!
        </Typography>
        <ContentStyle>
          <ConfirmationCodeForm navigateTo="/dashboard/profile/resetPassword" />
        </ContentStyle>
      </Container>
    </Page>
  );
}
