export function DashboardIcon(props) {
  const { width, height, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.841 1.8335H7.15933C3.82266 1.8335 1.8335 3.82266 1.8335 7.15933V14.8318C1.8335 18.1777 3.82266 20.1668 7.15933 20.1668H14.8318C18.1685 20.1668 20.1577 18.1777 20.1577 14.841V7.15933C20.1668 3.82266 18.1777 1.8335 14.841 1.8335ZM8.351 15.4918C8.351 15.7485 8.14933 15.9502 7.89266 15.9502H5.33516C5.0785 15.9502 4.87683 15.7485 4.87683 15.4918V11.2568C4.87683 10.6793 5.34433 10.2118 5.92183 10.2118H7.89266C8.14933 10.2118 8.351 10.4135 8.351 10.6702V15.4918ZM12.7327 15.4918C12.7327 15.7485 12.531 15.9502 12.2743 15.9502H9.71683C9.46016 15.9502 9.2585 15.7485 9.2585 15.4918V7.09516C9.2585 6.51766 9.726 6.05016 10.3035 6.05016H11.6968C12.2743 6.05016 12.7418 6.51766 12.7418 7.09516V15.4918H12.7327ZM17.1235 15.4918C17.1235 15.7485 16.9218 15.9502 16.6652 15.9502H14.1077C13.851 15.9502 13.6493 15.7485 13.6493 15.4918V12.2377C13.6493 11.981 13.851 11.7793 14.1077 11.7793H16.0785C16.656 11.7793 17.1235 12.2468 17.1235 12.8243V15.4918Z"
        fill={color}
      />
    </svg>
  );
}

export function AnalysisProcessIcon(props) {
  const { width = 20, height = 20, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1414 4.0751L11.2581 1.9001C10.5414 1.58343 9.45807 1.58343 8.74141 1.9001L3.85807 4.0751C2.62474 4.6251 2.44141 5.3751 2.44141 5.7751C2.44141 6.1751 2.62474 6.9251 3.85807 7.4751L8.74141 9.6501C9.09974 9.80843 9.54974 9.89176 9.99974 9.89176C10.4497 9.89176 10.8997 9.80843 11.2581 9.6501L16.1414 7.4751C17.3747 6.9251 17.5581 6.1751 17.5581 5.7751C17.5581 5.3751 17.3831 4.6251 16.1414 4.0751Z"
        fill={color}
      />
      <path
        d="M10.0003 14.1998C9.68359 14.1998 9.36693 14.1332 9.07526 14.0082L3.45859 11.5082C2.60026 11.1248 1.93359 10.0998 1.93359 9.15817C1.93359 8.8165 2.20859 8.5415 2.55026 8.5415C2.89193 8.5415 3.16693 8.8165 3.16693 9.15817C3.16693 9.60817 3.54193 10.1915 3.95859 10.3748L9.57526 12.8748C9.84193 12.9915 10.1503 12.9915 10.4169 12.8748L16.0336 10.3748C16.4503 10.1915 16.8253 9.6165 16.8253 9.15817C16.8253 8.8165 17.1003 8.5415 17.4419 8.5415C17.7836 8.5415 18.0586 8.8165 18.0586 9.15817C18.0586 10.0915 17.3919 11.1248 16.5336 11.5082L10.9169 14.0082C10.6336 14.1332 10.3169 14.1998 10.0003 14.1998Z"
        fill={color}
      />
      <path
        d="M10.0003 18.3331C9.68359 18.3331 9.36693 18.2665 9.07526 18.1415L3.45859 15.6415C2.53359 15.2331 1.93359 14.3081 1.93359 13.2915C1.93359 12.9498 2.20859 12.6748 2.55026 12.6748C2.89193 12.6748 3.16693 12.9498 3.16693 13.2915C3.16693 13.8165 3.47526 14.2915 3.95859 14.5081L9.57526 17.0081C9.84193 17.1248 10.1503 17.1248 10.4169 17.0081L16.0336 14.5081C16.5086 14.2998 16.8253 13.8165 16.8253 13.2915C16.8253 12.9498 17.1003 12.6748 17.4419 12.6748C17.7836 12.6748 18.0586 12.9498 18.0586 13.2915C18.0586 14.3081 17.4586 15.2248 16.5336 15.6415L10.9169 18.1415C10.6336 18.2665 10.3169 18.3331 10.0003 18.3331Z"
        fill={color}
      />
    </svg>
  );
}

export function FishIcon(props) {
  const { width = 45, height = 45, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.5053 5.47603C47.5667 5.56017 39.6317 7.08169 32.3299 10.159C24.3041 13.5416 17.0624 18.8264 11.6627 25.61C10.9623 26.4883 10.237 27.4345 9.17927 27.8316C8.50593 28.0837 7.76301 28.0846 7.07788 28.3015C6.39275 28.5176 5.71705 29.0765 5.75125 29.7871C6.33614 30.6822 7.49179 31.2142 8.55427 31.3883C9.61676 31.5634 10.7264 31.5949 11.704 32.0406C12.9799 32.6241 13.836 33.8165 14.7712 34.8562C17.2381 37.5961 20.6319 39.5052 24.2746 40.1984C26.5611 40.6338 28.9526 40.6092 31.133 41.4141C32.3759 41.8727 33.5068 42.5869 34.7178 43.1262C36.9619 44.1235 39.4984 44.8296 41.943 44.5415C40.726 43.3545 39.5704 42.0746 38.785 40.5726C38.0008 39.0721 37.6081 37.3194 37.976 35.6694C38.2496 34.4502 38.9194 33.3115 39.4913 32.17C40.9206 32.2658 42.3415 32.4837 43.7271 32.8419C44.1564 32.9541 44.5821 33.094 44.9877 33.269C45.6941 33.5763 46.3438 33.9318 46.9594 34.3371C47.4605 34.6693 48.0985 34.1898 47.8992 33.6274C47.4712 32.4268 47.1657 31.1932 47.1716 29.927C47.1764 29.1736 47.2919 28.4232 47.4629 27.6889C48.0089 25.3625 49.1527 23.1453 50.7365 21.3293C51.1256 20.8826 50.7518 20.1989 50.1645 20.2992C48.0349 20.6643 46.0148 21.6889 44.1292 22.787C42.4535 23.7638 40.792 24.8186 39.022 25.5885C38.8251 25.2913 38.6293 24.9914 38.4524 24.6855C37.4855 23.0213 37.0244 21.0435 37.3369 19.1509C37.5303 17.9826 38.0067 16.8764 38.5798 15.8381C39.384 14.384 40.4029 13.0211 41.7307 12.0118C42.772 11.2196 43.9736 10.6654 45.1658 10.118C48.5431 8.56493 51.9664 6.84924 55.5713 5.9463C55.846 5.87885 55.7883 5.47253 55.5053 5.47603Z"
        fill={color}
      />
      <path
        d="M24.0192 47.9756L25.1561 48.9542L25.1928 48.9115L25.2262 48.8662L24.0192 47.9756ZM7.0102 48.7892C7.49102 48.0444 8.35693 47.2506 9.33209 47.0348C10.1468 46.8545 11.4478 46.9897 13.1388 48.9542L15.4125 46.997C13.206 44.4337 10.8532 43.6256 8.68393 44.1057C6.67512 44.5502 5.22693 46.0202 4.4898 47.162L7.0102 48.7892ZM13.1388 48.9542C15.4084 51.5908 17.9648 52.2924 20.2787 51.8548C22.4543 51.4434 24.1959 50.0696 25.1561 48.9542L22.8824 46.997C22.2186 47.7681 21.0371 48.6582 19.7213 48.9071C18.5437 49.1298 17.0403 48.8881 15.4125 46.997L13.1388 48.9542ZM25.2262 48.8662C25.6418 48.3029 26.5387 47.5626 27.7758 47.3492C28.9484 47.147 30.6529 47.3801 32.8165 49.1394L34.7092 46.8118C32.001 44.6096 29.4426 44.0174 27.2659 44.3929C25.1536 44.7572 23.6146 45.9976 22.8122 47.085L25.2262 48.8662ZM32.8165 49.1394C35.5247 51.3416 38.0831 51.9337 40.2598 51.5581C42.3721 51.1937 43.9111 49.9531 44.7134 48.8657L42.2994 47.0846C41.8837 47.648 40.9869 48.3884 39.7498 48.6018C38.5771 48.8041 36.8727 48.5711 34.7092 46.8118L32.8165 49.1394ZM44.7134 48.8657C45.1291 48.3024 46.0259 47.5621 47.263 47.3487C48.4356 47.1464 50.14 47.3795 52.3036 49.139L54.1964 46.8114C51.4882 44.6091 48.9298 44.0168 46.753 44.3923C44.6407 44.7567 43.1017 45.9972 42.2994 47.0846L44.7134 48.8657Z"
        fill={color}
      />
      <path
        d="M24.0192 55.4756L25.1561 56.4542L25.1928 56.4115L25.2262 56.3662L24.0192 55.4756ZM7.0102 56.2892C7.49102 55.5444 8.35693 54.7506 9.33209 54.5348C10.1468 54.3545 11.4478 54.4897 13.1388 56.4542L15.4125 54.497C13.206 51.9337 10.8532 51.1256 8.68393 51.6057C6.67512 52.0502 5.22693 53.5202 4.4898 54.662L7.0102 56.2892ZM13.1388 56.4542C15.4084 59.0908 17.9648 59.7924 20.2787 59.3548C22.4543 58.9434 24.1959 57.5696 25.1561 56.4542L22.8824 54.497C22.2186 55.2681 21.0371 56.1582 19.7213 56.4071C18.5437 56.6298 17.0403 56.3881 15.4125 54.497L13.1388 56.4542ZM25.2262 56.3662C25.6418 55.8029 26.5387 55.0626 27.7758 54.8492C28.9484 54.647 30.6529 54.8801 32.8165 56.6394L34.7092 54.3118C32.001 52.1096 29.4426 51.5174 27.2659 51.8929C25.1536 52.2572 23.6146 53.4976 22.8122 54.585L25.2262 56.3662ZM32.8165 56.6394C35.5247 58.8416 38.0831 59.4337 40.2598 59.0581C42.3721 58.6937 43.9111 57.4531 44.7134 56.3657L42.2994 54.5846C41.8837 55.148 40.9869 55.8884 39.7498 56.1018C38.5771 56.3041 36.8727 56.0711 34.7092 54.3118L32.8165 56.6394ZM44.7134 56.3657C45.1291 55.8024 46.0259 55.0621 47.263 54.8487C48.4356 54.6464 50.14 54.8795 52.3036 56.639L54.1964 54.3114C51.4882 52.1091 48.9298 51.5168 46.753 51.8923C44.6407 52.2567 43.1017 53.4972 42.2994 54.5846L44.7134 56.3657Z"
        fill={color}
      />
    </svg>
  );
}

export function ClockIcon(props) {
  const { width, height, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4998 1.6665C5.90817 1.6665 2.1665 5.40817 2.1665 9.99984C2.1665 14.5915 5.90817 18.3332 10.4998 18.3332C15.0915 18.3332 18.8332 14.5915 18.8332 9.99984C18.8332 5.40817 15.0915 1.6665 10.4998 1.6665ZM14.1248 12.9748C14.0082 13.1748 13.7998 13.2832 13.5832 13.2832C13.4748 13.2832 13.3665 13.2582 13.2665 13.1915L10.6832 11.6498C10.0415 11.2665 9.5665 10.4248 9.5665 9.68317V6.2665C9.5665 5.92484 9.84984 5.6415 10.1915 5.6415C10.5332 5.6415 10.8165 5.92484 10.8165 6.2665V9.68317C10.8165 9.98317 11.0665 10.4248 11.3248 10.5748L13.9082 12.1165C14.2082 12.2915 14.3082 12.6748 14.1248 12.9748Z"
        fill={color}
      />
    </svg>
  );
}

export function LabelIcon(props) {
  const { width, height, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1774 7.97498L14.0249 3.82248C13.1541 2.95165 11.9533 2.48415 10.7249 2.54832L6.1416 2.76832C4.30827 2.85082 2.85077 4.30832 2.7591 6.13248L2.5391 10.7158C2.4841 11.9442 2.94244 13.145 3.81327 14.0158L7.96577 18.1683C9.67077 19.8733 12.4391 19.8733 14.1533 18.1683L18.1774 14.1442C19.8916 12.4483 19.8916 9.67998 18.1774 7.97498ZM8.70827 11.3483C7.25077 11.3483 6.06827 10.1658 6.06827 8.70832C6.06827 7.25082 7.25077 6.06832 8.70827 6.06832C10.1658 6.06832 11.3483 7.25082 11.3483 8.70832C11.3483 10.1658 10.1658 11.3483 8.70827 11.3483Z"
        fill={color}
      />
    </svg>
  );
}

export function LogIcon(props) {
  const { width = 20, height = 20, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158Z"
        fill={color}
      />
      <path
        d="M12.2998 16.0502C10.3415 16.1918 10.3415 19.0252 12.2998 19.1668H16.9331C17.4915 19.1668 18.0415 18.9585 18.4498 18.5835C19.8248 17.3835 19.0915 14.9835 17.2831 14.7585C16.6331 10.8502 10.9831 12.3335 12.3165 16.0585"
        fill={color}
      />
      <path
        d="M17.9163 9.32484V12.0332C17.9163 12.2832 17.5497 12.3998 17.3663 12.2248C16.9663 11.8332 16.4747 11.5332 15.908 11.3582C14.508 10.9248 12.883 11.3498 11.8747 12.4165C11.1997 13.1165 10.858 14.0082 10.8663 14.9748C10.8663 15.1332 10.7913 15.2748 10.6663 15.3665C9.99967 15.8748 9.58301 16.6748 9.58301 17.5998C9.58301 17.6665 9.58301 17.7332 9.59134 17.7998C9.60801 18.0665 9.41634 18.3248 9.14134 18.3248H6.72467C4.15801 18.3248 2.08301 16.6582 2.08301 13.6832V6.30817C2.08301 3.33317 4.15801 1.6665 6.72467 1.6665H10.258C10.7163 1.6665 11.0913 2.0415 11.0913 2.49984V4.90817C11.0913 6.8915 12.6913 8.4915 14.6747 8.4915H17.083C17.5413 8.4915 17.9163 8.8665 17.9163 9.32484Z"
        fill={color}
      />
    </svg>
  );
}

export function UploadFolderIcon(props) {
  const { width = 20, height = 20, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3731 6.46938C17.8005 7.01403 17.3292 7.70817 16.6369 7.70817H2.66699C2.11471 7.70817 1.66699 7.26046 1.66699 6.70817V5.34984C1.66699 3.3165 3.31699 1.6665 5.35033 1.6665H7.28366C8.64199 1.6665 9.06699 2.10817 9.60866 2.83317L10.7753 4.38317C11.0337 4.72484 11.067 4.7665 11.5503 4.7665H13.8753C15.2942 4.7665 16.5591 5.43201 17.3731 6.46938Z"
        fill={color}
      />
      <path
        d="M18.3204 9.95463C18.3185 9.40367 17.8713 8.95802 17.3204 8.95803L2.66697 8.95828C2.1147 8.95829 1.66699 9.40601 1.66699 9.95828V13.875C1.66699 16.3333 3.66699 18.3333 6.12533 18.3333H13.8753C16.3337 18.3333 18.3337 16.3333 18.3337 13.875L18.3204 9.95463ZM12.0837 13.9583H10.6753V15.4166C10.6753 15.7583 10.392 16.0416 10.0503 16.0416C9.70033 16.0416 9.42533 15.7583 9.42533 15.4166V13.9583H7.91699C7.57533 13.9583 7.29199 13.675 7.29199 13.3333C7.29199 12.9916 7.57533 12.7083 7.91699 12.7083H9.42533V11.25C9.42533 10.9083 9.70033 10.625 10.0503 10.625C10.392 10.625 10.6753 10.9083 10.6753 11.25V12.7083H12.0837C12.4253 12.7083 12.7087 12.9916 12.7087 13.3333C12.7087 13.675 12.4253 13.9583 12.0837 13.9583Z"
        fill={color}
      />
    </svg>
  );
}

export function UploadFileIcon(props) {
  const { width, height, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM11.53 13.53C11.38 13.68 11.19 13.75 11 13.75C10.81 13.75 10.62 13.68 10.47 13.53L9.75 12.81V17C9.75 17.41 9.41 17.75 9 17.75C8.59 17.75 8.25 17.41 8.25 17V12.81L7.53 13.53C7.24 13.82 6.76 13.82 6.47 13.53C6.18 13.24 6.18 12.76 6.47 12.47L8.47 10.47C8.54 10.41 8.61 10.36 8.69 10.32C8.71 10.31 8.74 10.3 8.76 10.29C8.82 10.27 8.88 10.26 8.95 10.25C8.98 10.25 9 10.25 9.03 10.25C9.11 10.25 9.19 10.27 9.27 10.3C9.28 10.3 9.28 10.3 9.29 10.3C9.37 10.33 9.45 10.39 9.51 10.45C9.52 10.46 9.53 10.46 9.53 10.47L11.53 12.47C11.82 12.76 11.82 13.24 11.53 13.53Z"
        fill={color}
      />
      <path
        d="M17.4302 8.80999C18.3802 8.81999 19.7002 8.81999 20.8302 8.81999C21.4002 8.81999 21.7002 8.14999 21.3002 7.74999C19.8602 6.29999 17.2802 3.68999 15.8002 2.20999C15.3902 1.79999 14.6802 2.07999 14.6802 2.64999V6.13999C14.6802 7.59999 15.9202 8.80999 17.4302 8.80999Z"
        fill={color}
      />
    </svg>
  );
}

export function BinIcon(props) {
  const { width = 20, height = 21, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5584 4.85817C16.2167 4.72484 14.875 4.62484 13.525 4.54984V4.5415L13.3417 3.45817C13.2167 2.6915 13.0334 1.5415 11.0834 1.5415H8.90005C6.95838 1.5415 6.77505 2.6415 6.64172 3.44984L6.46672 4.5165C5.69172 4.5665 4.91672 4.6165 4.14172 4.6915L2.44172 4.85817C2.09172 4.8915 1.84172 5.19984 1.87505 5.5415C1.90838 5.88317 2.20838 6.13317 2.55838 6.09984L4.25838 5.93317C8.62505 5.49984 13.0251 5.6665 17.4417 6.10817C17.4667 6.10817 17.4834 6.10817 17.5084 6.10817C17.8251 6.10817 18.1 5.8665 18.1334 5.5415C18.1584 5.19984 17.9084 4.8915 17.5584 4.85817Z"
        fill={color}
      />
      <path
        d="M16.025 7.2835C15.825 7.07516 15.55 6.9585 15.2666 6.9585H4.73329C4.44995 6.9585 4.16662 7.07516 3.97495 7.2835C3.78329 7.49183 3.67495 7.77516 3.69162 8.06683L4.20829 16.6168C4.29995 17.8835 4.41662 19.4668 7.32495 19.4668H12.675C15.5833 19.4668 15.7 17.8918 15.7916 16.6168L16.3083 8.07516C16.325 7.77516 16.2166 7.49183 16.025 7.2835ZM11.3833 15.2918H8.60829C8.26662 15.2918 7.98329 15.0085 7.98329 14.6668C7.98329 14.3252 8.26662 14.0418 8.60829 14.0418H11.3833C11.725 14.0418 12.0083 14.3252 12.0083 14.6668C12.0083 15.0085 11.725 15.2918 11.3833 15.2918ZM12.0833 11.9585H7.91662C7.57495 11.9585 7.29162 11.6752 7.29162 11.3335C7.29162 10.9918 7.57495 10.7085 7.91662 10.7085H12.0833C12.425 10.7085 12.7083 10.9918 12.7083 11.3335C12.7083 11.6752 12.425 11.9585 12.0833 11.9585Z"
        fill={color}
      />
    </svg>
  );
}

export function VideoIcon(props) {
  const { width, height, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3873 5.65567C19.0115 5.454 18.2232 5.24317 17.1507 5.99484L15.8032 6.94817C15.7023 4.09734 14.4648 2.979 11.4582 2.979H5.95817C2.82317 2.979 1.604 4.19817 1.604 7.33317V14.6665C1.604 16.7748 2.74984 19.0207 5.95817 19.0207H11.4582C14.4648 19.0207 15.7023 17.9023 15.8032 15.0515L17.1507 16.0048C17.719 16.4082 18.214 16.5365 18.6082 16.5365C18.9473 16.5365 19.2132 16.4357 19.3873 16.344C19.7632 16.1515 20.3957 15.629 20.3957 14.3182V7.6815C20.3957 6.37067 19.7632 5.84817 19.3873 5.65567ZM10.0832 10.4315C9.139 10.4315 8.35984 9.6615 8.35984 8.70817C8.35984 7.75484 9.139 6.98484 10.0832 6.98484C11.0273 6.98484 11.8065 7.75484 11.8065 8.70817C11.8065 9.6615 11.0273 10.4315 10.0832 10.4315Z"
        fill={color}
      />
    </svg>
  );
}

export function VideoFileIcon(props) {
  const { width = 32, height = 32, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.6404 2.6665H12.3604V8.47984H19.6404V2.6665Z" fill="#212B36" />
      <path
        d="M21.6396 2.6665V8.47984H29.1597C28.4797 4.81317 25.773 2.67984 21.6396 2.6665Z"
        fill={color}
      />
      <path
        d="M2.66699 10.48V21.5866C2.66699 26.44 5.56033 29.3333 10.4137 29.3333H21.587C26.4403 29.3333 29.3337 26.44 29.3337 21.5866V10.48H2.66699ZM19.2537 21.5733L16.4803 23.1733C15.8937 23.5066 15.3203 23.68 14.787 23.68C14.387 23.68 14.027 23.5866 13.6937 23.4C12.9203 22.96 12.4937 22.0533 12.4937 20.88V17.68C12.4937 16.5066 12.9203 15.6 13.6937 15.16C14.467 14.7066 15.4537 14.7866 16.4803 15.3866L19.2537 16.9866C20.2803 17.5733 20.8403 18.4 20.8403 19.2933C20.8403 20.1866 20.267 20.9733 19.2537 21.5733Z"
        fill={color}
      />
      <path
        d="M10.3598 2.6665C6.22651 2.67984 3.51984 4.81317 2.83984 8.47984H10.3598V2.6665Z"
        fill={color}
      />
    </svg>
  );
}

export function CalendarIcon(props) {
  const { width, height, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5627 2.67V1.5C12.5627 1.1925 12.3077 0.9375 12.0002 0.9375C11.6927 0.9375 11.4377 1.1925 11.4377 1.5V2.625H6.56267V1.5C6.56267 1.1925 6.30767 0.9375 6.00017 0.9375C5.69267 0.9375 5.43767 1.1925 5.43767 1.5V2.67C3.41267 2.8575 2.43017 4.065 2.28017 5.8575C2.26517 6.075 2.44517 6.255 2.65517 6.255H15.3452C15.5627 6.255 15.7427 6.0675 15.7202 5.8575C15.5702 4.065 14.5877 2.8575 12.5627 2.67Z"
        fill={color}
      />
      <path
        d="M15 7.37988H3C2.5875 7.37988 2.25 7.71738 2.25 8.12988V12.7499C2.25 14.9999 3.375 16.4999 6 16.4999H12C14.625 16.4999 15.75 14.9999 15.75 12.7499V8.12988C15.75 7.71738 15.4125 7.37988 15 7.37988ZM6.9075 13.6574C6.8325 13.7249 6.75 13.7774 6.66 13.8149C6.57 13.8524 6.4725 13.8749 6.375 13.8749C6.2775 13.8749 6.18 13.8524 6.09 13.8149C6 13.7774 5.9175 13.7249 5.8425 13.6574C5.7075 13.5149 5.625 13.3199 5.625 13.1249C5.625 12.9299 5.7075 12.7349 5.8425 12.5924C5.9175 12.5249 6 12.4724 6.09 12.4349C6.27 12.3599 6.48 12.3599 6.66 12.4349C6.75 12.4724 6.8325 12.5249 6.9075 12.5924C7.0425 12.7349 7.125 12.9299 7.125 13.1249C7.125 13.3199 7.0425 13.5149 6.9075 13.6574ZM7.065 10.7849C7.0275 10.8749 6.975 10.9574 6.9075 11.0324C6.8325 11.0999 6.75 11.1524 6.66 11.1899C6.57 11.2274 6.4725 11.2499 6.375 11.2499C6.2775 11.2499 6.18 11.2274 6.09 11.1899C6 11.1524 5.9175 11.0999 5.8425 11.0324C5.775 10.9574 5.7225 10.8749 5.685 10.7849C5.6475 10.6949 5.625 10.5974 5.625 10.4999C5.625 10.4024 5.6475 10.3049 5.685 10.2149C5.7225 10.1249 5.775 10.0424 5.8425 9.96738C5.9175 9.89988 6 9.84738 6.09 9.80988C6.27 9.73488 6.48 9.73488 6.66 9.80988C6.75 9.84738 6.8325 9.89988 6.9075 9.96738C6.975 10.0424 7.0275 10.1249 7.065 10.2149C7.1025 10.3049 7.125 10.4024 7.125 10.4999C7.125 10.5974 7.1025 10.6949 7.065 10.7849ZM9.5325 11.0324C9.4575 11.0999 9.375 11.1524 9.285 11.1899C9.195 11.2274 9.0975 11.2499 9 11.2499C8.9025 11.2499 8.805 11.2274 8.715 11.1899C8.625 11.1524 8.5425 11.0999 8.4675 11.0324C8.3325 10.8899 8.25 10.6949 8.25 10.4999C8.25 10.3049 8.3325 10.1099 8.4675 9.96738C8.5425 9.89988 8.625 9.84738 8.715 9.80988C8.895 9.72738 9.105 9.72738 9.285 9.80988C9.375 9.84738 9.4575 9.89988 9.5325 9.96738C9.6675 10.1099 9.75 10.3049 9.75 10.4999C9.75 10.6949 9.6675 10.8899 9.5325 11.0324Z"
        fill={color}
      />
    </svg>
  );
}

export function FilterIcon(props) {
  const { width, height, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5.25C2.25 5.6625 2.5875 6 3 6H15C15.4125 6 15.75 5.6625 15.75 5.25C15.75 4.8375 15.4125 4.5 15 4.5H3C2.5875 4.5 2.25 4.8375 2.25 5.25ZM8.25 13.5H9.75C10.1625 13.5 10.5 13.1625 10.5 12.75C10.5 12.3375 10.1625 12 9.75 12H8.25C7.8375 12 7.5 12.3375 7.5 12.75C7.5 13.1625 7.8375 13.5 8.25 13.5ZM12.75 9.75H5.25C4.8375 9.75 4.5 9.4125 4.5 9C4.5 8.5875 4.8375 8.25 5.25 8.25H12.75C13.1625 8.25 13.5 8.5875 13.5 9C13.5 9.4125 13.1625 9.75 12.75 9.75Z"
        fill={color}
      />
    </svg>
  );
}

export function LocationIcon(props) {
  const { width, height, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4648 6.3375C14.6773 2.8725 11.6548 1.3125 8.99983 1.3125C8.99983 1.3125 8.99983 1.3125 8.99233 1.3125C6.34483 1.3125 3.31483 2.865 2.52733 6.33C1.64983 10.2 4.01983 13.4775 6.16483 15.54C6.95983 16.305 7.97983 16.6875 8.99983 16.6875C10.0198 16.6875 11.0398 16.305 11.8273 15.54C13.9723 13.4775 16.3423 10.2075 15.4648 6.3375ZM8.99983 10.095C7.69483 10.095 6.63733 9.0375 6.63733 7.7325C6.63733 6.4275 7.69483 5.37 8.99983 5.37C10.3048 5.37 11.3623 6.4275 11.3623 7.7325C11.3623 9.0375 10.3048 10.095 8.99983 10.095Z"
        fill={color}
      />
    </svg>
  );
}

export function ExportIcon(props) {
  const { width, height, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.375 7.6425H13.2075C11.43 7.6425 9.9825 6.195 9.9825 4.4175V2.25C9.9825 1.8375 9.645 1.5 9.2325 1.5H6.0525C3.7425 1.5 1.875 3 1.875 5.6775V12.3225C1.875 15 3.7425 16.5 6.0525 16.5H11.9475C14.2575 16.5 16.125 15 16.125 12.3225V8.3925C16.125 7.98 15.7875 7.6425 15.375 7.6425ZM9.21 11.835L7.71 13.335C7.6575 13.3875 7.59 13.4325 7.5225 13.455C7.455 13.485 7.3875 13.5 7.3125 13.5C7.2375 13.5 7.17 13.485 7.1025 13.455C7.0425 13.4325 6.9825 13.3875 6.9375 13.3425C6.93 13.335 6.9225 13.335 6.9225 13.3275L5.4225 11.8275C5.205 11.61 5.205 11.25 5.4225 11.0325C5.64 10.815 6 10.815 6.2175 11.0325L6.75 11.58V8.4375C6.75 8.13 7.005 7.875 7.3125 7.875C7.62 7.875 7.875 8.13 7.875 8.4375V11.58L8.415 11.04C8.6325 10.8225 8.9925 10.8225 9.21 11.04C9.4275 11.2575 9.4275 11.6175 9.21 11.835Z"
        fill={color}
      />
      <path
        d="M13.0723 6.60762C13.7848 6.61512 14.7748 6.61512 15.6223 6.61512C16.0498 6.61512 16.2748 6.11262 15.9748 5.81262C14.8948 4.72512 12.9598 2.76762 11.8498 1.65762C11.5423 1.35012 11.0098 1.56012 11.0098 1.98762V4.60512C11.0098 5.70012 11.9398 6.60762 13.0723 6.60762Z"
        fill={color}
      />
    </svg>
  );
}

export function EyeIcon(props) {
  const { width, height, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7082 7.62506C15.7832 4.60006 12.9665 2.8584 9.99984 2.8584C8.5165 2.8584 7.07484 3.29173 5.75817 4.10007C4.4415 4.91673 3.25817 6.1084 2.2915 7.62506C1.45817 8.9334 1.45817 11.0584 2.2915 12.3667C4.2165 15.4001 7.03317 17.1334 9.99984 17.1334C11.4832 17.1334 12.9248 16.7001 14.2415 15.8917C15.5582 15.0751 16.7415 13.8834 17.7082 12.3667C18.5415 11.0667 18.5415 8.9334 17.7082 7.62506ZM9.99984 13.3667C8.13317 13.3667 6.63317 11.8584 6.63317 10.0001C6.63317 8.14173 8.13317 6.6334 9.99984 6.6334C11.8665 6.6334 13.3665 8.14173 13.3665 10.0001C13.3665 11.8584 11.8665 13.3667 9.99984 13.3667Z"
        fill={color}
      />
      <path
        d="M10 7.6167C8.69167 7.6167 7.625 8.68337 7.625 10C7.625 11.3084 8.69167 12.375 10 12.375C11.3083 12.375 12.3833 11.3084 12.3833 10C12.3833 8.6917 11.3083 7.6167 10 7.6167Z"
        fill={color}
      />
    </svg>
  );
}

export function DiagramIcon(props) {
  const { width, height, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5938 15.9375C13.9592 15.9375 16.6875 13.2092 16.6875 9.84375C16.6875 6.47826 13.9592 3.75 10.5938 3.75C7.22826 3.75 4.5 6.47826 4.5 9.84375C4.5 13.2092 7.22826 15.9375 10.5938 15.9375Z"
        fill={color}
      />
      <path
        d="M10.125 41.25C13.2316 41.25 15.75 38.7316 15.75 35.625C15.75 32.5184 13.2316 30 10.125 30C7.0184 30 4.5 32.5184 4.5 35.625C4.5 38.7316 7.0184 41.25 10.125 41.25Z"
        fill={color}
      />
      <path
        d="M36.375 41.25C39.4816 41.25 42 38.7316 42 35.625C42 32.5184 39.4816 30 36.375 30C33.2684 30 30.75 32.5184 30.75 35.625C30.75 38.7316 33.2684 41.25 36.375 41.25Z"
        fill={color}
      />
      <path
        d="M36.6938 29.9625C34.875 24.75 29.9625 21.2438 24.4313 21.2438C24.4125 21.2438 24.3938 21.2438 24.375 21.2438L17.7562 21.2625C14.9062 21.3 12.3563 19.3688 11.5875 16.5938V13.1438C11.5875 12.3563 10.95 11.7188 10.1438 11.7188C9.3375 11.7188 8.71875 12.3563 8.71875 13.1438L8.71875 34.1812C8.71875 34.9688 9.35625 35.6063 10.1438 35.6063C10.9313 35.6063 11.5875 34.9688 11.5875 34.1812L11.5875 21.7688C13.2375 23.2312 15.3938 24.1313 17.7375 24.1313C17.7563 24.1313 17.7562 24.1313 17.775 24.1313L24.3937 24.1125C24.4125 24.1125 24.4125 24.1125 24.4313 24.1125C28.725 24.1125 32.5687 26.8313 33.975 30.9C34.2 31.5 34.7438 31.875 35.3438 31.875C35.4938 31.875 35.6625 31.8562 35.8125 31.8C36.5625 31.5375 36.9562 30.7125 36.6938 29.9625Z"
        fill={color}
      />
    </svg>
  );
}

export function LabelRoundIcon(props) {
  const { width = 20, height = 20, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6917 4.86699C14.3917 4.44199 13.8917 4.16699 13.3333 4.16699L4.16667 4.17533C3.25 4.17533 2.5 4.91699 2.5 5.83366V14.167C2.5 15.0837 3.25 15.8253 4.16667 15.8253L13.3333 15.8337C13.8917 15.8337 14.3917 15.5587 14.6917 15.1337L18.3333 10.0003L14.6917 4.86699Z"
        fill={color}
      />
    </svg>
  );
}

export function DownloadRoundIcon(props) {
  const { width = 20, height = 20, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 1.66699C5.39984 1.66699 1.6665 5.40033 1.6665 10.0003C1.6665 14.6003 5.39984 18.3337 9.99984 18.3337C14.5998 18.3337 18.3332 14.6003 18.3332 10.0003C18.3332 5.40033 14.5998 1.66699 9.99984 1.66699ZM12.9415 10.8587L10.4415 13.3587C10.3165 13.4837 10.1582 13.542 9.99984 13.542C9.8415 13.542 9.68317 13.4837 9.55817 13.3587L7.05817 10.8587C6.8165 10.617 6.8165 10.217 7.05817 9.97533C7.29984 9.73366 7.69984 9.73366 7.9415 9.97533L9.37484 11.4087V7.08366C9.37484 6.74199 9.65817 6.45866 9.99984 6.45866C10.3415 6.45866 10.6248 6.74199 10.6248 7.08366V11.4087L12.0582 9.97533C12.2998 9.73366 12.6998 9.73366 12.9415 9.97533C13.1832 10.217 13.1832 10.617 12.9415 10.8587Z"
        fill={color}
      />
    </svg>
  );
}

export function DiagramTypeIcon(props) {
  const { width = 20, height = 20, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83317 6.66699H3.33317C2.4165 6.66699 1.6665 5.91699 1.6665 5.00033V3.33366C1.6665 2.41699 2.4165 1.66699 3.33317 1.66699H5.83317C6.74984 1.66699 7.49984 2.41699 7.49984 3.33366V5.00033C7.49984 5.91699 6.74984 6.66699 5.83317 6.66699Z"
        fill={color}
      />
      <path
        d="M17.3335 5.83333H14.3335C13.7835 5.83333 13.3335 5.38333 13.3335 4.83333V3.5C13.3335 2.95 13.7835 2.5 14.3335 2.5H17.3335C17.8835 2.5 18.3335 2.95 18.3335 3.5V4.83333C18.3335 5.38333 17.8835 5.83333 17.3335 5.83333Z"
        fill={color}
      />
      <path
        d="M17.3335 12.0833H14.3335C13.7835 12.0833 13.3335 11.6333 13.3335 11.0833V9.75C13.3335 9.2 13.7835 8.75 14.3335 8.75H17.3335C17.8835 8.75 18.3335 9.2 18.3335 9.75V11.0833C18.3335 11.6333 17.8835 12.0833 17.3335 12.0833Z"
        fill={color}
      />
      <path
        opacity="0.96"
        d="M13.3333 11.042C13.675 11.042 13.9583 10.7587 13.9583 10.417C13.9583 10.0753 13.675 9.79199 13.3333 9.79199H11.0417V4.79199H13.3333C13.675 4.79199 13.9583 4.50866 13.9583 4.16699C13.9583 3.82533 13.675 3.54199 13.3333 3.54199H7.5C7.15833 3.54199 6.875 3.82533 6.875 4.16699C6.875 4.50866 7.15833 4.79199 7.5 4.79199H9.79167V15.0003C9.79167 16.267 10.8167 17.292 12.0833 17.292H13.3333C13.675 17.292 13.9583 17.0087 13.9583 16.667C13.9583 16.3253 13.675 16.042 13.3333 16.042H12.0833C11.5083 16.042 11.0417 15.5753 11.0417 15.0003V11.042H13.3333Z"
        fill={color}
      />
      <path
        d="M17.3335 18.3333H14.3335C13.7835 18.3333 13.3335 17.8833 13.3335 17.3333V16C13.3335 15.45 13.7835 15 14.3335 15H17.3335C17.8835 15 18.3335 15.45 18.3335 16V17.3333C18.3335 17.8833 17.8835 18.3333 17.3335 18.3333Z"
        fill={color}
      />
    </svg>
  );
}

export function VideoSuggestionIcon(props) {
  const { width = 20, height = 20, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9334 8.4335C14.6584 8.36683 14.3667 8.3335 14.0667 8.3335C11.8834 8.3335 10.1084 10.1085 10.1084 12.2918C10.1084 13.0335 10.3167 13.7335 10.6834 14.3335C10.9917 14.8502 11.4167 15.2918 11.9334 15.6085C12.5501 16.0168 13.2834 16.2502 14.0667 16.2502C15.5167 16.2502 16.7751 15.4752 17.4584 14.3335C17.8251 13.7335 18.0251 13.0335 18.0251 12.2918C18.0251 10.4085 16.7084 8.82516 14.9334 8.4335ZM16.0417 11.7752L13.9251 13.7252C13.8084 13.8335 13.6501 13.8918 13.5001 13.8918C13.3417 13.8918 13.1834 13.8335 13.0584 13.7085L12.0834 12.7335C11.8417 12.4918 11.8417 12.0918 12.0834 11.8502C12.3251 11.6085 12.7251 11.6085 12.9667 11.8502L13.5167 12.4002L15.1917 10.8585C15.4501 10.6252 15.8417 10.6418 16.0751 10.8918C16.3084 11.1502 16.2917 11.5418 16.0417 11.7752Z"
        fill={color}
      />
      <path
        d="M17.1501 3.34984V5.19984C17.1501 5.87484 16.7334 6.7165 16.3168 7.1415L16.1668 7.27484C16.0501 7.38317 15.8751 7.40817 15.7251 7.35817C15.5584 7.29984 15.3918 7.25817 15.2251 7.2165C14.8584 7.12484 14.4668 7.08317 14.0668 7.08317C11.1918 7.08317 8.85843 9.4165 8.85843 12.2915C8.85843 13.2415 9.11676 14.1748 9.60843 14.9748C10.0251 15.6748 10.6084 16.2582 11.2418 16.6498C11.4334 16.7748 11.5084 17.0415 11.3418 17.1915C11.2834 17.2415 11.2251 17.2832 11.1668 17.3248L10.0001 18.0832C8.91676 18.7582 7.4251 17.9998 7.4251 16.6498V12.1915C7.4251 11.5998 7.09176 10.8415 6.75843 10.4248L3.6001 7.05817C3.18343 6.63317 2.8501 5.87484 2.8501 5.37484V3.43317C2.8501 2.42484 3.6001 1.6665 4.50843 1.6665H15.4918C16.4001 1.6665 17.1501 2.42484 17.1501 3.34984Z"
        fill={color}
      />
    </svg>
  );
}

export function Top30Icon(props) {
  const { width = 20, height = 20, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4915 1.6665H6.50817C3.47484 1.6665 1.6665 3.47484 1.6665 6.50817V13.4832C1.6665 16.5248 3.47484 18.3332 6.50817 18.3332H13.4832C16.5165 18.3332 18.3248 16.5248 18.3248 13.4915V6.50817C18.3332 3.47484 16.5248 1.6665 13.4915 1.6665ZM12.2165 11.4415L11.1498 12.0582L10.0832 12.6748C8.70817 13.4665 7.58317 12.8165 7.58317 11.2332V9.99984V8.7665C7.58317 7.17484 8.70817 6.53317 10.0832 7.32484L11.1498 7.9415L12.2165 8.55817C13.5915 9.34984 13.5915 10.6498 12.2165 11.4415Z"
        fill={color}
      />
    </svg>
  );
}

export function PublishSectionIcon(props) {
  const { width = 19, height = 18, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0H18C18.55 0 19 0.45 19 1V7C19 7.55 18.55 8 18 8H1C0.45 8 0 7.55 0 7V1C0 0.45 0.45 0 1 0ZM1 10H18C18.55 10 19 10.45 19 11V17C19 17.55 18.55 18 18 18H1C0.45 18 0 17.55 0 17V11C0 10.45 0.45 10 1 10Z"
        fill={color}
      />
    </svg>
  );
}

export function ExpandScreenIcon(props) {
  const { width = 24, height = 24, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9999 5C19.9999 4.44772 19.5522 4 18.9999 4H13.9999C13.4477 4 12.9999 4.44772 12.9999 5C12.9999 5.55228 13.4477 6 13.9999 6H16.5699L13.2899 9.29C13.1006 9.47777 12.9941 9.73336 12.9941 10C12.9941 10.2666 13.1006 10.5222 13.2899 10.71C13.4777 10.8993 13.7333 11.0058 13.9999 11.0058C14.2666 11.0058 14.5222 10.8993 14.7099 10.71L17.9999 7.42V10C17.9999 10.5523 18.4477 11 18.9999 11C19.5522 11 19.9999 10.5523 19.9999 10V5Z"
        fill={color}
      />
      <path
        d="M10.71 13.2899C10.5222 13.1006 10.2666 12.9941 10 12.9941C9.73336 12.9941 9.47777 13.1006 9.29 13.2899L6 16.5699V13.9999C6 13.4477 5.55228 12.9999 5 12.9999C4.44772 12.9999 4 13.4477 4 13.9999V18.9999C4 19.5522 4.44772 19.9999 5 19.9999H10C10.5523 19.9999 11 19.5522 11 18.9999C11 18.4477 10.5523 17.9999 10 17.9999H7.42L10.71 14.7099C10.8993 14.5222 11.0058 14.2666 11.0058 13.9999C11.0058 13.7333 10.8993 13.4777 10.71 13.2899Z"
        fill={color}
      />
    </svg>
  );
}

export function FishFilterIcon(props) {
  const { width = 24, height = 24, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9021 0.173837C15.7267 0.207491 12.5527 0.816099 9.63196 2.04704C6.42163 3.40008 3.52497 5.51399 1.36509 8.22741C1.08491 8.57874 0.794815 8.95723 0.371708 9.11607C0.102372 9.21689 -0.194796 9.21727 -0.468849 9.30404C-0.742902 9.39048 -1.01318 9.61401 -0.999502 9.89827C-0.765543 10.2563 -0.303284 10.4691 0.121709 10.5388C0.546703 10.6088 0.990563 10.6214 1.3816 10.7997C1.89197 11.0331 2.23441 11.51 2.60846 11.9259C3.59524 13.0219 4.95277 13.7855 6.40983 14.0628C7.32444 14.2369 8.28102 14.2271 9.15318 14.5491C9.65034 14.7325 10.1027 15.0182 10.5871 15.2339C11.4848 15.6328 12.4994 15.9153 13.4772 15.8C12.9904 15.3252 12.5281 14.8132 12.214 14.2125C11.9003 13.6123 11.7432 12.9112 11.8904 12.2512C11.9998 11.7635 12.2678 11.308 12.4965 10.8514C13.0682 10.8898 13.6366 10.9769 14.1908 11.1202C14.3625 11.1651 14.5328 11.221 14.6951 11.291C14.9776 11.4139 15.2375 11.5562 15.4837 11.7183C15.6842 11.8511 15.9394 11.6594 15.8597 11.4344C15.6885 10.9541 15.5663 10.4607 15.5687 9.95423C15.5705 9.65285 15.6168 9.35272 15.6852 9.05899C15.9036 8.12841 16.3611 7.24156 16.9946 6.51514C17.1502 6.33647 17.0007 6.06299 16.7658 6.10309C15.9139 6.24914 15.1059 6.659 14.3517 7.09822C13.6814 7.48895 13.0168 7.91085 12.3088 8.21882C12.23 8.09994 12.1517 7.97998 12.081 7.85764C11.6942 7.19195 11.5098 6.40083 11.6348 5.6438C11.7121 5.17648 11.9027 4.73399 12.1319 4.31867C12.4536 3.73704 12.8612 3.19186 13.3923 2.78816C13.8088 2.47128 14.2894 2.24957 14.7663 2.03061C16.1172 1.4094 17.4866 0.723122 18.9285 0.361943C19.0384 0.334964 19.0153 0.172437 18.9021 0.173837Z"
        fill={color}
      />
    </svg>
  );
}
export function TimeFilterIcon(props) {
  const { width = 24, height = 24, color = 'currentColor' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99957 3.48755C5.41457 3.48755 2.49707 6.40505 2.49707 9.99005C2.49707 13.575 5.41457 16.5 8.99957 16.5C12.5846 16.5 15.5021 13.5825 15.5021 9.99755C15.5021 6.41255 12.5846 3.48755 8.99957 3.48755ZM9.56207 9.75005C9.56207 10.0575 9.30707 10.3125 8.99957 10.3125C8.69207 10.3125 8.43707 10.0575 8.43707 9.75005V6.00005C8.43707 5.69255 8.69207 5.43755 8.99957 5.43755C9.30707 5.43755 9.56207 5.69255 9.56207 6.00005V9.75005Z"
        fill={color}
      />
      <path
        d="M11.168 2.5875H6.83297C6.53297 2.5875 6.29297 2.3475 6.29297 2.0475C6.29297 1.7475 6.53297 1.5 6.83297 1.5H11.168C11.468 1.5 11.708 1.74 11.708 2.04C11.708 2.34 11.468 2.5875 11.168 2.5875Z"
        fill={color}
      />
    </svg>
  );
}
