import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Input,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography
} from '@mui/material';
import Iconify from 'components/Iconify';
import { useEffect, useRef, useState } from 'react';
import VideoPreviewThumbnailSide from 'components/VideoPreviewThumbnailSide';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandScreenIcon, VideoIcon } from 'components/icon/DashboardIcon';
import { ApiGetOneObject, ApiUpdateObject } from 'api';
import FavouriteIconRender from 'components/FavouriteIconRender';
import { isEmpty } from 'lodash';
import { fDateTimeNoMillisec } from 'utils/formatTime';
import { updatePublishListName } from 'sections/@dashboard/app/videoListSlice';
import { setNewVideoPlaying } from './videoPlayingSlice';
import { VideoJS } from './VideoJS';

const TableRowStyled = styled(TableRow)({
  height: 10
});

const TableCellStyled = styled(TableCell)({
  height: 'auto !important',
  padding: 0
});

export default function AppPreviewVideo({ handleOpenVideoModal, handleCloseVideoModal, isModal }) {
  const { previewList, publishList, usePreviewList } = useSelector((state) => state.videoListSlice);
  const { playing } = useSelector((state) => state.videoPlayingSlice);

  const dispatch = useDispatch();

  const [videoLink, setVideoLink] = useState('video');
  const [listRender, setListRender] = useState(usePreviewList ? previewList : publishList);
  const [isEdit, setIsEdit] = useState(false);
  const [fileName, setFileName] = useState(null);

  const playerRef = useRef(null);

  useEffect(() => {
    if (playing && playing.name) {
      setFileName(playing.name);
    }
  }, [playing]);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    preload: 'none',
    sources: [
      {
        src: videoLink,
        type: 'video/mp4'
      }
    ]
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      //
    });

    player.on('dispose', () => {
      //
    });
  };

  const handleNextVideoButton = () => {
    const index = listRender.findIndex((x) => x.id === playing.id);
    let nextIndex = index + 1;
    if (nextIndex === listRender.length) {
      nextIndex = 0;
    }
    dispatch(
      setNewVideoPlaying({
        playing: listRender[nextIndex]
      })
    );
  };

  const handlePreviousVideoButton = () => {
    const index = listRender.findIndex((x) => x.id === playing.id);
    let previousIndex = index - 1;
    if (previousIndex < 0) {
      previousIndex = listRender.length - 1;
    }
    dispatch(
      setNewVideoPlaying({
        playing: listRender[previousIndex]
      })
    );
  };

  useEffect(() => {
    if (isEmpty(playing) && !isEmpty(listRender)) {
      dispatch(setNewVideoPlaying({ playing: listRender[0] }));
    }
    if (playing) {
      if (listRender.findIndex((x) => x.id === playing.id) === -1) {
        dispatch(setNewVideoPlaying({ playing: listRender[0] }));
      }
    }
  }, [listRender]);

  useEffect(() => {
    setListRender(usePreviewList ? previewList : publishList);
  }, [previewList, publishList]);

  useEffect(() => {
    if (playing && playing.id) {
      ApiGetOneObject(playing.id)
        .then((response) => {
          setVideoLink(response.data.path);
        })
        .catch((err) => console.error(err));
    }
  }, [playing]);

  const handleChangeVideoName = async (id, name) => {
    setIsEdit(!isEdit);

    if (isEdit) {
      await ApiUpdateObject(id, { name: fileName }).catch((err) => console.error(err));
      dispatch(updatePublishListName({ id, name: fileName }));
    }
  };

  const renderName = (id, name) => (
    <div>
      {!isEdit ? (
        <>
          {fileName || '-'}
          <Iconify
            icon="bi:pencil-square"
            sx={{ ml: 1, cursor: 'pointer' }}
            width={14}
            height={14}
            onClick={() => setIsEdit(!isEdit)}
          />
        </>
      ) : (
        <>
          <Input
            autoFocus
            value={fileName}
            sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            onChange={(e) => setFileName(e.target.value)}
          />
          <Iconify
            icon="bi:pencil-square"
            sx={{ ml: 1, cursor: 'pointer' }}
            width={14}
            height={14}
            onClick={() => handleChangeVideoName(id, name)}
          />
        </>
      )}
    </div>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  function NextPreviousButton({ isModal }) {
    return (
      <Stack direction="row" spacing={2} justifyContent={!isModal && 'space-between'}>
        <Button
          variant="contained"
          startIcon={<Iconify icon="ooui:previous-ltr" />}
          sx={{
            width: '162px',
            backgroundColor: '#DFE3E8',
            color: 'black',
            py: 1.5,
            '&:hover': {
              backgroundColor: '#919EAB',
              borderColor: '#919EAB'
            }
          }}
          onClick={handlePreviousVideoButton}
          color="greytone"
        >
          Previous
        </Button>
        <Button
          variant="contained"
          endIcon={<Iconify icon="ooui:previous-rtl" />}
          sx={{ width: '162px', py: 1.5 }}
          onClick={handleNextVideoButton}
        >
          Next
        </Button>
      </Stack>
    );
  }

  return (
    <Container maxWidth={false}>
      {listRender.length > 0 && playing ? (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4" sx={{ my: 3 }}>
              {!isModal && fileName}
            </Typography>
            {isModal ? (
              <IconButton onClick={handleCloseVideoModal}>
                <Iconify icon="clarity:close-line" />
              </IconButton>
            ) : (
              <IconButton onClick={handleOpenVideoModal}>
                <ExpandScreenIcon color="#2AAAE2" />
              </IconButton>
            )}
          </Stack>
          <Divider />
          <Grid container spacing={2} sx={{ my: 3 }}>
            <Grid item xs={12} md={8}>
              <VideoJS options={videoJsOptions} onReady={handlePlayerReady} videoLink={videoLink} />
              {isModal && (
                <Stack
                  direction="row"
                  spacing={4}
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={{ mt: 4 }}
                >
                  <NextPreviousButton isModal={isModal} />
                  <FavouriteIconRender
                    id={playing.id}
                    value={
                      listRender.find((x) => x.id === playing.id) &&
                      listRender.find((x) => x.id === playing.id).favourite
                    }
                    size={35}
                  />
                </Stack>
              )}
              <Box sx={{ mt: 5 }}>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: 'none'
                    }
                  }}
                >
                  <colgroup>
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '65%' }} />
                  </colgroup>
                  <TableBody>
                    <TableRowStyled>
                      <TableCellStyled component="th" scope="row">
                        <Typography variant="subtitle2">Name</Typography>
                      </TableCellStyled>
                      {playing.name && (
                        <TableCellStyled>{renderName(playing.id, playing.name)}</TableCellStyled>
                      )}
                      {!isModal && (
                        <TableCellStyled rowSpan={2}>
                          <FavouriteIconRender
                            id={playing.id}
                            value={
                              listRender.find((x) => x.id === playing.id) &&
                              listRender.find((x) => x.id === playing.id).favourite
                            }
                            size={35}
                          />
                        </TableCellStyled>
                      )}
                    </TableRowStyled>
                    <TableRowStyled>
                      <TableCellStyled component="th" scope="row">
                        <Typography variant="subtitle2">Location</Typography>
                      </TableCellStyled>
                      <TableCellStyled>{playing.location}</TableCellStyled>
                    </TableRowStyled>
                    <TableRowStyled>
                      <TableCellStyled component="th" scope="row">
                        <Typography variant="subtitle2">Date/Time</Typography>
                      </TableCellStyled>
                      <TableCellStyled>{fDateTimeNoMillisec(playing.date)}</TableCellStyled>
                    </TableRowStyled>
                    <TableRowStyled>
                      <TableCellStyled component="th" scope="row">
                        <Typography variant="subtitle2">Number of Fish</Typography>
                      </TableCellStyled>
                      <TableCellStyled>{playing.amount}</TableCellStyled>
                    </TableRowStyled>
                  </TableBody>
                </Table>
              </Box>
              {!isModal && (
                <Stack
                  direction="row"
                  spacing={4}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 4 }}
                >
                  <NextPreviousButton />
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} md={4} maxHeight="100px">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: isModal ? '750px' : '550px',
                  overflow: 'hidden',
                  overflowY: 'scroll',
                  scrollbarWidth: 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none'
                  }
                }}
              >
                {listRender.map((item, index) => (
                  <Box
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: playing.name === item.name && 'rgba(42, 170, 226, 0.08)',
                      padding: '10px',
                      alignItems: 'center',
                      borderRadius: '16px'
                    }}
                    key={index}
                  >
                    <VideoPreviewThumbnailSide
                      id={item.id}
                      name={item.name}
                      amount={item.amount}
                      location={item.location}
                      date={item.date}
                      image={item.thumbnail}
                      favourite={item.favourite}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item width="100%" justifyContent="center">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ mt: 5 }}
          >
            <VideoIcon width={51} height={43} color="#919EAB" />
            <Typography variant="body2">
              No video could be found with the current confidence interval of 95% and above
            </Typography>
          </Stack>
        </Grid>
      )}
    </Container>
  );
}
