import { Stack, styled } from '@mui/material';
import { useState } from 'react';
import EdiText from 'react-editext';
import './style.css';
import Iconify from 'components/Iconify';
import { ApiUpdateLocation } from 'api';

export default function TextEditField(props) {
  const { id, rows, setRows } = props;
  const [edit, setEdit] = useState(false);

  const handleOnClick = (value) => {
    ApiUpdateLocation(id, { name: value })
      .then((response) => {
        const newArr = [...rows];

        for (let i = 0; i < newArr.length; i += 1) {
          if (newArr[i].id === id) {
            newArr[i] = { ...newArr[i], name: value };
          }
        }
        setRows(newArr);
        toggleSetEdit();
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        }
        alert('Edit location failed');
      });
  };

  const toggleSetEdit = () => {
    const statusNow = edit;
    setEdit(!statusNow);
  };

  return (
    <Stack direction="row" spacing={2} id={props.id}>
      <EdiText
        {...props}
        onSave={handleOnClick}
        onCancel={toggleSetEdit}
        editing={edit}
        style={{ cursor: 'pointer' }}
      />
      <Iconify
        icon="eva:edit-fill"
        sx={{ cursor: 'pointer' }}
        onClick={toggleSetEdit}
        className={edit ? 'hidden' : undefined}
        color="#637381"
      />
    </Stack>
  );
}
