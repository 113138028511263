import { createSlice } from '@reduxjs/toolkit';

const videoListSlice = createSlice({
  name: 'videoListSlice',
  initialState: {
    previewList: [],
    publishList: [],
    usePreviewList: true
  },
  reducers: {
    setNewVideoPreviewList: (state, action) => {
      state.previewList = action.payload.previewList;
    },
    setNewVideoPublishList: (state, action) => {
      state.publishList = action.payload.publishList;
    },
    addNewVideoPreviewList: (state, action) => {
      const index = state.previewList.findIndex((x) => action.payload.id === x.id);
      if (index === -1) {
        state.previewList = [...state.previewList, action.payload];
      } else {
        state.previewList = [
          ...state.previewList.slice(0, index),
          action.payload,
          ...state.previewList.slice(index + 1)
        ];
      }
    },
    addNewVideoPublishList: (state, action) => {
      videoListSlice.caseReducers.removeVideoPublishListBySection(state, action);
      state.publishList = [...state.publishList, action.payload];
    },
    removeVideoPublishListBySection: (state, action) => {
      const index = state.publishList.findIndex((x) => action.payload.section === x.section);
      if (index !== -1) {
        // waiting transfer to previewList
        state.publishList = [
          ...state.publishList.slice(0, index),
          ...state.publishList.slice(index + 1)
        ];
      }
    },
    removeVideoPreviewListById: (state, action) => {
      const index = state.previewList.findIndex((x) => action.payload.id === x.id);
      if (index !== -1) {
        // waiting transfer to previewList
        state.previewList = [
          ...state.previewList.slice(0, index),
          ...state.previewList.slice(index + 1)
        ];
      }
    },
    updatePreviewListFavourite: (state, action) => {
      const index = state.previewList.findIndex((x) => x.id === action.payload.id);
      if (index === -1) {
        state.previewList = [...state.previewList];
      } else {
        state.previewList = [
          ...state.previewList.slice(0, index),
          { ...state.previewList[index], favourite: action.payload.favourite },
          ...state.previewList.slice(index + 1)
        ];
      }
    },
    updatePublishListFavourite: (state, action) => {
      const index = state.publishList.findIndex((x) => x.id === action.payload.id);
      if (index === -1) {
        state.publishList = [...state.publishList];
      } else {
        state.publishList = [
          ...state.publishList.slice(0, index),
          { ...state.publishList[index], favourite: action.payload.favourite },
          ...state.publishList.slice(index + 1)
        ];
      }
    },
    updatePublishListSection: (state, action) => {
      const index = state.publishList.findIndex((x) => x.id === action.payload.id);
      if (index === -1) {
        state.publishList = [...state.publishList];
      } else {
        state.publishList = [
          ...state.publishList.slice(0, index),
          { ...state.publishList[index], section: action.payload.section },
          ...state.publishList.slice(index + 1)
        ];
      }
    },
    updateUsePreviewList: (state, action) => {
      state.usePreviewList = action.payload;
    },
    updatePublishListName: (state, action) => {
      const Publicindex = state.publishList.findIndex((x) => x.id === action.payload.id);

      if (Publicindex === -1) {
        state.publishList = [...state.publishList];
      } else {
        state.publishList = [
          ...state.publishList.slice(0, Publicindex),
          { ...state.publishList[Publicindex], name: action.payload.name },
          ...state.publishList.slice(Publicindex + 1)
        ];
      }
      //

      const PreviewIndex = state.previewList.findIndex((x) => x.id === action.payload.id);

      if (PreviewIndex === -1) {
        state.previewList = [...state.previewList];
      } else {
        state.previewList = [
          ...state.previewList.slice(0, PreviewIndex),
          { ...state.previewList[PreviewIndex], name: action.payload.name },
          ...state.previewList.slice(PreviewIndex + 1)
        ];
      }
    }
  }
});

export const {
  setNewVideoPreviewList,
  setNewVideoPublishList,
  addNewVideoPreviewList,
  addNewVideoPublishList,
  removeVideoPublishListBySection,
  removeVideoPreviewListById,
  updatePreviewListFavourite,
  updatePublishListFavourite,
  updateUsePreviewList,
  updatePublishListName
} = videoListSlice.actions;
export default videoListSlice.reducer;
