// component

import { DashboardIcon, VideoIcon, LabelIcon } from 'components/icon/DashboardIcon';

// ----------------------------------------------------------------------

const sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: <DashboardIcon />,
    group: ['Master', 'Reviewer', 'View-only'],
    chip: false
  },
  {
    title: 'Add video',
    path: '/dashboard/video',
    icon: <VideoIcon />,
    group: ['Master'],
    chip: false
  },
  {
    title: 'Fish classification',
    path: '/dashboard/label',
    icon: <LabelIcon />,
    group: ['Reviewer'],
    chip: true
  }
];

export default sidebarConfig;
