import { Navigate, Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// components
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// ----------------------------------------------------------------------

export default function SessionLayout() {
  const user = useSelector((state) => state.userSlice);
  const userGroup = user.group[0];

  if (userGroup) {
    return <Navigate to="/dashboard/app" replace />;
  }

  return (
    <>
      <HeaderStyle />
      <Outlet />
    </>
  );
}
