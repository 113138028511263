import { useState } from 'react';
import { Button, MenuItem, Menu } from '@mui/material';
import PropTypes from 'prop-types';

BasicMenu.propTypes = {
  topic: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  items: PropTypes.array,
  setItem: PropTypes.func,
  setItemId: PropTypes.func
};

export default function BasicMenu({ topic, icon, items, setItem, setItemId }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (ev) => {
    setAnchorEl(null);
    const itemClick = ev.target.getAttribute('value');
    if (itemClick) {
      setItem(itemClick);
      setItemId(ev.target.getAttribute('id'));
    }
  };

  return (
    <div>
      <Button
        id="basic-button"
        color="inherit"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={icon}
        sx={{ textTransform: 'unset' }}
        style={{ fontSize: '16px' }}
      >
        {topic}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          sx: { maxHeight: 250 }
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={handleClose}
            sx={{ minWidth: '150px' }}
            value={item.name}
            id={item.id}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
