import { useFormik } from 'formik';
import { useState } from 'react';
// material
import { Box, Container, Grid, Tab, Tabs, Typography } from '@mui/material';
// components
import { TabContext, TabPanel } from '@mui/lab';
import Page from 'components/Page';

//
import { DiagramTypeIcon, DownloadRoundIcon, LabelRoundIcon } from 'components/icon/DashboardIcon';
import UnknownLabel from 'sections/@dashboard/label/UnknownLabel';
import Types from 'sections/@dashboard/label/Types';
import LowConfidence from 'sections/@dashboard/label/LowConfidence';

// ----------------------------------------------------------------------

export default function FishClassification() {
  const [openFilter, setOpenFilter] = useState(false);

  const [tab, setTab] = useState('Label');

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  return (
    <Page title="Fish Classification | Aquatic monitoring system">
      <Container sx={{ marginLeft: 0 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Fish Classification
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tab} onChange={handleTab} aria-label="report export">
                    <Tab
                      icon={<LabelRoundIcon width={30} height={20} />}
                      iconPosition="start"
                      label="Label"
                      value="Label"
                    />
                    <Tab
                      icon={<DownloadRoundIcon width={30} height={20} />}
                      iconPosition="start"
                      label="Download"
                      value="Download"
                    />
                    <Tab
                      icon={<DiagramTypeIcon width={30} height={20} />}
                      iconPosition="start"
                      label="Types"
                      value="Types"
                    />
                  </Tabs>
                </Box>
                <TabPanel value="Label">
                  <LowConfidence />
                </TabPanel>
                <TabPanel value="Download">
                  <UnknownLabel />
                </TabPanel>
                <TabPanel value="Types">
                  <Types />
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
