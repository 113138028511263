import React, { useEffect } from 'react';
import { Box, Container, Grid, Stack, styled, Typography } from '@mui/material';
import VideoPreviewThumbnail from 'components/VideoPreviewThumbnail';
import { useSelector } from 'react-redux';
import { VideoIcon } from 'components/icon/DashboardIcon';

export default function AppVideoSuggestion(props) {
  const { handleOpenVideoModal, videos, isLoading } = props;
  const { previewList } = useSelector((state) => state.videoListSlice);

  if (isLoading) {
    return (
      <Box>
        <Grid item width="100%" justifyContent="center">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ mt: 5 }}
          >
            <VideoIcon width={51} height={43} color="#919EAB" />
            <Typography variant="body2">Loading...</Typography>
          </Stack>
        </Grid>
      </Box>
    );
  }
  return (
    <Box>
      <Grid container spacing={2}>
        {videos.length > 0 ? (
          videos.map((item, index) => (
            <Grid item key={index}>
              <VideoPreviewThumbnail
                showPublish={false}
                id={item.id}
                name={item.name}
                amount={item.amount}
                location={item.location}
                date={item.date}
                image={item.thumbnail}
                favourite={item.favourite}
                publish={item.publish}
                sessionId={item.sessionId}
                handleOpenVideoModal={handleOpenVideoModal}
              />
            </Grid>
          ))
        ) : (
          <Grid item width="100%" justifyContent="center">
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ mt: 5 }}
            >
              <VideoIcon width={51} height={43} color="#919EAB" />
              <Typography variant="body2">
                No video could be found with the current confidence interval of 95% and above
              </Typography>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
