import {
  Box,
  Checkbox,
  LinearProgress,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  styled,
  Button,
  IconButton
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { visuallyHidden } from '@mui/utils';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { BinIcon } from 'components/icon/DashboardIcon';
import { getComparator, stableSort } from 'utils/sortHelper';
import Iconify from 'components/Iconify';
import { removeVideoFile, updateVideoStatus } from './videoUploadSlice';
import './style.css';

const TableCellStyled = styled(TableCell)({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
});

const columns = [
  { id: 'name', label: 'Name', width: '30%' },
  {
    id: 'date',
    label: 'Upload date',
    width: '20%'
  },
  { id: 'location', label: 'Location', width: '20%' }
];

export default function VideoLoading({
  uploadQueue,
  setUploadQueue,
  reUploadQueue,
  setReUploadQueue
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');

  const filesUpload = useSelector((state) => state.videoUploadSlice);
  const dispatch = useDispatch();

  const MySwal = withReactContent(Swal);

  const abortRequest = (controller) => {
    controller.abort();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRemoveFile = (event) => {
    console.error(event.currentTarget.getAttribute('value'));
    const fileId = event.currentTarget.getAttribute('value');
    MySwal.fire({
      title: 'Are you sure ?',
      html: 'Do you really want to delete these ? <br>This process cannot be undone.',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      reverseButtons: true,
      confirmButtonColor: '#FF4842',
      iconHtml: '<img src="/static/cancelButton.png">',
      customClass: {
        icon: 'no-border'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeVideoFile({ id: fileId }));
        abortRequest(uploadQueue.find((x) => x.id === fileId).controller);
        setUploadQueue(uploadQueue.filter((item) => item.id !== fileId));
      }
    });
  };

  const handleRemoveSelectedFile = () => {
    MySwal.fire({
      title: 'Are you sure ?',
      html: 'Do you really want to delete these ? <br>This process cannot be undone.',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      reverseButtons: true,
      confirmButtonColor: '#FF4842',
      iconHtml: '<img src="/static/cancelButton.png">',
      customClass: {
        icon: 'no-border'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        selected.forEach((item) => {
          dispatch(removeVideoFile({ id: item }));
          abortRequest(uploadQueue.find((x) => x.id === item).controller);
          setUploadQueue(uploadQueue.filter((item) => item.id !== item));
        });
      }
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filesUpload.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleReUploadFile = (event) => {
    const fileId = event.currentTarget.getAttribute('value');
    const reUploadFile = reUploadQueue.find((x) => x.id === fileId);
    if (reUploadFile) {
      dispatch(updateVideoStatus({ id: fileId, status: 'pending' }));
      setUploadQueue((prevState) => [...prevState, reUploadFile]);
      setReUploadQueue(reUploadQueue.filter((item) => item.id !== fileId));
    }
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table sx={{ whiteSpace: 'nowrap', tableLayout: 'fixed' }}>
          <colgroup>
            <col style={{ width: '5%' }} />
            {columns.map((column, index) => (
              <col key={index} style={{ width: column.width }} />
            ))}
            <col style={{ width: '20%' }} />
            <col style={{ width: '5%' }} />
          </colgroup>
          <TableHead sx={{ backgroundColor: '#F4F6F8' }}>
            <TableRow>
              <TableCell colSpan={5}>
                <Button
                  variant="string"
                  startIcon={<BinIcon color="#637381" />}
                  onClick={handleRemoveSelectedFile}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={selected.length > 0 && selected.length < filesUpload.length}
                  checked={filesUpload.length > 0 && selected.length === filesUpload.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts'
                  }}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCellStyled
                  key={column.id}
                  align={column.align}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
              <TableCell colSpan={2} />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filesUpload, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .filter((row) => row.progress < 100)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onClick={(event) => handleClick(event, row.id)}
                        checked={isItemSelected}
                        inputProps={{
                          'aria-label': 'select all desserts'
                        }}
                      />
                    </TableCell>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCellStyled key={column.id} align={column.align}>
                          {value}
                        </TableCellStyled>
                      );
                    })}
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            value={row.progress}
                            color={row.status === 'failed' ? 'error' : 'primary'}
                          />
                        </Box>
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            {row.status === 'failed' ? (
                              <IconButton value={row.id} onClick={handleReUploadFile}>
                                <Iconify icon="eva:refresh-fill" sx={{ width: '15px' }} />
                              </IconButton>
                            ) : (
                              `${Math.round(row.progress)}%`
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box value={row.id} onClick={handleRemoveFile} sx={{ cursor: 'pointer' }}>
                        <BinIcon color="#919EAB" width={16} height={16} />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 20]}
        component="div"
        count={filesUpload.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
