import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Collapse,
  Alert,
  Dialog,
  Grid,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Button
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PasswordChecklist from 'react-password-checklist';
import Iconify from 'components/Iconify';
import { CognitoUserFactory } from 'utils/CognitoFactory';
import PropTypes from 'prop-types';
import { removeForgotPasswordData } from '../forgotPassword/forgotPasswordSlice';
// ----------------------------------------------------------------------

ResetPasswordForm.propTypes = {
  navigateTo: PropTypes.string
};

export default function ResetPasswordForm({ navigateTo }) {
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const user = useSelector((state) => state.forgotPasswordSlice);
  const dispatch = useDispatch();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleBackToLogin = () => {
    navigate(navigateTo, { replace: true });
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmation: ''
    },
    onSubmit: async () => {
      const username = user.email;
      const cognitoUser = CognitoUserFactory(username);
      cognitoUser.confirmPassword(user.code, password, {
        onSuccess(result) {
          dispatch(removeForgotPasswordData());
          // alert('Your password has been successfully changed');
          handleDialogOpen();
        },
        onFailure(err) {
          console.error(err);
          setShowAlert(true);
        }
      });
    },
    onFailure: (err) => {
      console.error('onFailure', err.message);
    }
  });

  const { handleSubmit, isSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Input your new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            type={showConfirmPassword ? 'text' : 'password'}
            label="Confirm your new password"
            value={confirmation}
            onChange={(e) => setConfirmation(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowConfirmPassword((prev) => !prev)}>
                    <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Collapse in={showAlert}>
            <Alert
              onClose={() => {
                setShowAlert(false);
              }}
              severity="error"
              sx={{ mb: 2 }}
            >
              OTP not correct. Please verify the OTP used
            </Alert>
          </Collapse>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!passwordValid}
          >
            Submit
          </Button>
          <PasswordChecklist
            rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
            minLength={8}
            value={password}
            valueAgain={confirmation}
            onChange={(isValid) => {
              setPasswordValid(isValid);
            }}
            iconComponents={{
              ValidIcon: (
                <Iconify icon="akar-icons:circle-check-fill" sx={{ color: '#54d62c', mr: 1 }} />
              ),
              InvalidIcon: (
                <Iconify icon="akar-icons:circle-check" sx={{ color: '#919EAB', mr: 1 }} />
              )
            }}
            messages={{
              minLength: ' Minimum 8 characters.',
              specialChar: ' At least 1 special character.',
              number: ' At least 1 numeral.',
              capital: ' At least 1 capital letter.',
              match: ' Password and confirmation are matching.'
            }}
          />
        </Stack>
      </Form>
      <Dialog
        open={dialogOpen}
        onClose={handleBackToLogin}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 5 }}>
            <Grid item>
              <Iconify
                icon="clarity:success-standard-line"
                sx={{ width: 50, height: 50, color: 'green' }}
              />
            </Grid>
          </Grid>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your password has been successfully changed
            </DialogContentText>
          </DialogContent>
        </Box>
        <DialogActions sx={{ textAlign: 'center', mb: 3 }}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item>
              <Button variant="contained" color="success" onClick={handleBackToLogin} autoFocus>
                OK
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
}
