import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Container, Typography } from '@mui/material';
// layouts
import Page from 'components/Page';
import Iconify from 'components/Iconify';
import AuthLayout from 'layouts/AuthLayout';
// components
import { ResetPasswordForm } from 'sections/authentication/resetPassword';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
  return (
    <RootStyle title="ResetPassword | Aquatic monitoring system">
      <AuthLayout />

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 2 }}>
            <Link variant="string" underline="none" component={RouterLink} to="/confirmationCode">
              <Iconify icon="eva:arrow-ios-back-fill" />
              Back
            </Link>
          </Box>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Reset password
            </Typography>
          </Box>

          <ResetPasswordForm navigateTo="/login" />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
