import {
  Box,
  Chip,
  IconButton,
  LinearProgress,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fDateTime, fDateTimeNoMillisec } from 'utils/formatTime';
import { ApiGetProcessingObjects, APIAnalysisML } from 'api';
import Iconify from 'components/Iconify';
import {
  removeAllProcessFile,
  setNewProcessFile,
  updateProcessStatus,
  updateProcessProgress
} from './videoProcessSlice';

TableUploadLayout.propTypes = {
  columns: PropTypes.array,
  withProgressBar: PropTypes.bool,
  srcKey: PropTypes.string,
  operator: PropTypes.string,
  compareValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const TableCellStyled = styled(TableCell)({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
});

export default function TableUploadLayout({
  columns,
  withProgressBar,
  srcKey,
  operator,
  compareValue
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [totalItems, setTotalItems] = useState(0);

  const filesUpload = useSelector((state) => state.videoProcessSlice);
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getProcessingObjects(`?page=${newPage + 1}&limit=${rowsPerPage}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getProcessingObjects(`?page=0&limit=${event.target.value}`);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const matchNameField = {
      name: 'name',
      date: 'createdAt',
      locationName: 'location'
    };
    const matchOrderField = {
      asc: 'ASC',
      desc: 'DESC'
    };
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    getProcessingObjects(`?sortBy=${matchNameField[property]}:${matchOrderField[order]}`);
  };

  const conditionalRuleTranslator = (src, srcKey, operator, compareValue) => {
    switch (operator) {
      case '===':
        return src[srcKey] === compareValue;
      case '!==':
        return src[srcKey] !== compareValue;
      case '>':
        return src[srcKey] > compareValue;
      case '<':
        return src[srcKey] < compareValue;
      default:
        return src[srcKey] === compareValue;
    }
  };

  const getProcessingObjects = (query) => {
    dispatch(removeAllProcessFile());

    if (withProgressBar) {
      query += '&filter.status=$in:pending,processing,failed';
    } else {
      query += '&filter.status=$in:completed';
    }
    ApiGetProcessingObjects(query).then(async (response) => {
      response.data.data.map((item) => {
        item.locationName = 'unknown';
        if (item.location) {
          item.locationName = item.location.name;
        }
        item.date = fDateTime(item.createdAt);
        if (item.meta) {
          item.startDate = item.meta.video_date;
        }
        dispatch(setNewProcessFile(item));
        return 'success';
      });
      setTotalItems(response.data.meta.totalItems);
    });
  };

  useEffect(() => {
    const query = '?page=1&limit=10';
    getProcessingObjects(query);
  }, []);

  const handleRerunAnalysis = (row) => {
    APIAnalysisML(row.id);
    dispatch(updateProcessStatus({ id: row.id, status: 'processing', processing: 0 }));
    dispatch(updateProcessProgress({ id: row.id, progress: 0 }));
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table sx={{ whiteSpace: 'nowrap', tableLayout: 'fixed' }}>
          <colgroup>
            {columns.map((column, index) => (
              <col key={index} style={{ width: column.width }} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCellStyled
                  key={column.id}
                  align={column.align}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  {column.id !== 'locationName' && column.id !== 'startDate' ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCellStyled>
              ))}
              {withProgressBar ? <TableCell /> : <TableCell>Status</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {filesUpload
              .filter((row) => conditionalRuleTranslator(row, srcKey, operator, compareValue))
              .map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id === 'startDate') {
                      value = fDateTimeNoMillisec(row[column.id]);
                    }
                    return (
                      <TableCellStyled key={column.id} align={column.align}>
                        {value}
                      </TableCellStyled>
                    );
                  })}
                  {withProgressBar ? (
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            value={row.processing}
                            color={row.status === 'failed' ? 'error' : 'secondary'}
                          />
                        </Box>
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            {row.status === 'failed' ? (
                              <IconButton onClick={() => handleRerunAnalysis(row)}>
                                <Iconify icon="eva:refresh-fill" sx={{ width: '15px' }} />
                              </IconButton>
                            ) : (
                              `${Math.round(row.processing)}%`
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Chip
                          label="Successful"
                          sx={{ backgroundColor: 'rgba(84, 214, 44, 0.16)', color: '#229A16' }}
                        />
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 20]}
        component="div"
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
