// material
import { Container, Typography, styled, Box, Link } from '@mui/material';
import Page from 'components/Page';
//
import { Link as RouterLink } from 'react-router-dom';
import EditProfileForm from 'sections/authentication/profile/editProfileForm';
import Iconify from 'components/Iconify';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '60vh',
  flexDirection: 'column',
  justifyContent: 'center'
}));

// ----------------------------------------------------------------------

export default function Profile() {
  return (
    <Page title="Profile | Aquatic monitoring system">
      <Container sx={{ marginLeft: 0 }}>
        <Box sx={{ mb: 2 }}>
          <Link variant="string" underline="none" component={RouterLink} to="/dashboard/app/">
            <Iconify icon="eva:arrow-ios-back-fill" />
            Back
          </Link>
        </Box>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Edit profile
        </Typography>
        <ContentStyle>
          <EditProfileForm />
        </ContentStyle>
      </Container>
    </Page>
  );
}
