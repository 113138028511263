// material
import { Grid } from '@mui/material';
import AreaCard from 'components/AreaCard';

const products = [
  {
    id: 1,
    src: 'https://2.imimg.com/data2/LX/LW/MY-4075233/golden-gold-fish-500x500.jpg',
    text: 'Gold Fish'
  },
  {
    id: 2,
    src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3FF5eUy0vCGM_d4Rg4ywRMtjsrMzk2BoN2A&usqp=CAU',
    text: 'Gold Fish'
  },
  {
    id: 3,
    src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOSvzPz5rSgogstW2QtoG2CqRK8HomO9MGqQ&usqp=CAU',
    text: 'Gold Fish'
  },
  {
    id: 4,
    src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXBWCPhND2xvwMTZzgx1CxnWpvBryzWTgCzw&usqp=CAU',
    text: 'Gold Fish'
  },
  {
    id: 5,
    src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSb_RS4kaP39PKr_a8oGzc5cftuDbDUaafNtw&usqp=CAU',
    text: 'Gold Fish'
  },
  {
    id: 6,
    src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1f4xk2_vaK9G0NsC7EhGwZIWWAicyz3oFsw&usqp=CAU',
    text: 'Gold Fish'
  },
  {
    id: 7,
    src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDmz2LYkCuOAIpomDtqzXmo-Hi63ZWh1KSPQ&usqp=CAU',
    text: 'Gold Fish'
  },
  {
    id: 8,
    src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBCqeCTkIrIvMmsqWuSPlzpARQKJzWbjVwMQ&usqp=CAU',
    text: 'Gold Fish'
  }
];

export default function LowConfidence() {
  return (
    <Grid container spacing={3}>
      {products.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={4}>
          <AreaCard src={product.src} text={product.text} />
        </Grid>
      ))}
    </Grid>
  );
}
