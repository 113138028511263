// material
import { Grid, Typography, Stack } from '@mui/material';
import { Pie, ResponsiveContainer, PieChart, Cell } from 'recharts';

// ----------------------------------------------------------------------

export default function AppConfidenceChart({ avgConfidence }) {
  const data = [
    { name: 'confidence', value: Number(avgConfidence) },
    { name: 'non-confidence', value: Number(1 - avgConfidence) }
  ];
  const COLORS = ['#252260', 'rgba(0.15, 0.08, 0.00, 0.14)'];

  return (
    <Stack height="220px" direction="row" justifyContent="space-around" alignItems="center">
      <ResponsiveContainer width={200} height="100%" minWidth="200px">
        <PieChart width={300} height={300}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={35}
            outerRadius={45}
            fill="#8884d8"
            dataKey="value"
            startAngle={90}
            endAngle={-270}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Grid container direction="column">
        <Typography variant="h3">{data[0].value * 100} %</Typography>
        <Typography sx={{ fontSize: '18px' }}>Average confidence level</Typography>
      </Grid>
    </Stack>
  );
}
