import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { activateRefreshToken } from 'utils/CognitoFactory';
import { setExpire } from 'sections/authentication/login/loginSlice';
import jwtDecode from 'jwt-decode';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const ModalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 713,
  height: 245,
  backgroundColor: 'background.paper',
  border: '2px solid #000',
  paddingY: 4,
  paddingX: 10,
  boxShadow: '0px 16px 32px -4px rgba(145, 158, 171, 0.12)',
  borderRadius: '16px'
};

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const { expire } = useSelector((state) => state.userSlice);
  const [triggerExpire, setTriggerExpire] = useState(true);
  const [leftTime, setLeftTime] = useState(3600);
  const [showTimeoutDialog, setShowTimeoutDialog] = useState(false);

  const dispatch = useDispatch();

  const controlNavBar = () => {
    const { body, documentElement, height } = document;

    let entireHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      documentElement.clientHeight,
      documentElement.scrollHeight,
      documentElement.offsetHeight
    );
    const windowHeight = height !== undefined ? height : body.offsetHeight;
    const navBarHeight = 92;

    if (typeof window !== 'undefined') {
      entireHeight = isScroll ? entireHeight + navBarHeight : entireHeight;
      if (window.scrollY > 0 && entireHeight - navBarHeight * 2 > windowHeight) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavBar);
      return () => {
        window.removeEventListener('scroll', controlNavBar);
      };
    }
  }, [lastScrollY]);

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       const secondsSinceEpoch = Math.round(new Date().getTime() / 1000);
  //       const left = expire - secondsSinceEpoch;
  //       setLeftTime(left);
  //       if (left <= 0) {
  //         window.location.href = '/public/logout';
  //       } else if (left <= 60) {
  //         setShowTimeoutDialog(true);
  //       } else {
  //         setShowTimeoutDialog(false);
  //       }
  //     }, 1000);
  //     return () => clearInterval(interval);
  //   }, [triggerExpire]);

  useEffect(() => {
    setTriggerExpire((prev) => !prev);
  }, [expire]);

  const handleRefreshToken = async () => {
    // await activateRefreshToken(jwtDecode(localStorage.getItem('access_token')).username, (e) =>
    //   dispatch(setExpire(e))
    // );
  };

  return (
    <RootStyle>
      <Box sx={{ display: `${isScroll && 'none'}` }}>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      </Box>
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle sx={{ paddingTop: `${isScroll && 0} !important` }}>
        <Outlet />
      </MainStyle>
      <Modal open={showTimeoutDialog} disableEscapeKeyDown>
        <Box sx={ModalBoxStyle}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                Your session is about to end
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                You’ve been inactive for a while. For your security, we’ll automatically sign you
                out in approximately {leftTime} seconds. Choose “Continue session” or “Logout”.
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                color="greytone"
                sx={{ mr: 2 }}
                onClick={() => (window.location.href = '/public/logout')}
              >
                Logout
              </Button>
              <Button
                variant="contained"
                sx={{ ml: 2, textTransform: 'unset !important' }}
                onClick={handleRefreshToken}
              >
                Continue session
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </RootStyle>
  );
}
