import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Button, styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CognitoUserFactory } from 'utils/CognitoFactory';
import { setEmailForgotPassword } from './forgotPasswordSlice';
// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async () => {
      const { email: username } = formik.values;
      const cognitoUser = CognitoUserFactory(username);
      dispatch(setEmailForgotPassword(username));

      cognitoUser.forgotPassword({
        onSuccess(result) {
          navigate('/confirmationCode', { replace: true });
        },
        onFailure(err) {
          console.error(err);
        }
      });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ textTransform: 'unset' }}
          >
            Reset password
          </Button>
          <Button variant="text" component={RouterLink} to="/login">
            Back
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
