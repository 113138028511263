import { useState } from 'react';
import {
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';

const columns = [
  { id: 'name', label: 'Name', minWidth: 200 },
  { id: 'location', label: 'Location', minWidth: 50 },
  {
    id: 'date',
    label: 'Start date / time',
    minWidth: 120
  },
  {
    id: 'unknown',
    label: 'Unknown'
  }
];

function createData(name, location, date, unknown) {
  return { name, location, date, unknown };
}

const rows = [
  createData('Oil Rig 02-394850', 'OilRig2', '29 Jun 2021 07:24PM', 29),
  createData('Oil Rig 02-394850', 'OilRig2', '29 Jun 2021 07:24PM', 29),
  createData('Oil Rig 02-394850', 'OilRig2', '29 Jun 2021 07:24PM', 29),
  createData('Oil Rig 02-394850', 'OilRig2', '29 Jun 2021 07:24PM', 29),
  createData('Oil Rig 02-394850', 'OilRig2', '29 Jun 2021 07:24PM', 29),
  createData('Oil Rig 02-394850', 'OilRig2', '29 Jun 2021 07:24PM', 29),
  createData('Oil Rig 02-394850', 'OilRig2', '29 Jun 2021 07:24PM', 29),
  createData('Oil Rig 02-394850', 'OilRig2', '29 Jun 2021 07:24PM', 29),
  createData('Oil Rig 02-394850', 'OilRig2', '29 Jun 2021 07:24PM', 29),
  createData('Oil Rig 02-394850', 'OilRig2', '29 Jun 2021 07:24PM', 29),
  createData('Oil Rig 02-394850', 'OilRig2', '29 Jun 2021 07:24PM', 29),
  createData('Oil Rig 02-394850', 'OilRig2', '29 Jun 2021 07:24PM', 29)
];

export default function UnknownLabel() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, ind) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={ind}>
                {columns.map((column, index) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={index} align={column.align}>
                      <Grid container>
                        {column.id === 'name' && (
                          <Grid item>
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDkhSC7MoLNGIfWgenKSjz5eDL9l81URz77NFRNwvYtXzjyASqSqB3dUVeuXKsJI0-Qsk&usqp=CAU"
                              alt="fish"
                              width={40}
                              height={40}
                              style={{ borderRadius: '20%' }}
                            />
                          </Grid>
                        )}
                        <Grid item xs container direction="column" sx={{ verticalAlign: 'center' }}>
                          {value}
                        </Grid>
                      </Grid>
                    </TableCell>
                  );
                })}
                <TableCell>
                  <Link to="#" underline="hover">
                    Start >
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
