import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    username: '',
    name: '',
    surname: '',
    expire: 3600,
    group: []
  },
  reducers: {
    setUserName: (state, action) => ({ ...state, username: action.payload }),
    setUserGroup: (state, action) => ({
      ...state,
      group: [action.payload]
    }),
    setExpire: (state, action) => ({ ...state, expire: action.payload }),
    removeUserName: (state, action) => {
      state.username = '';
      state.name = '';
      state.surname = '';
      state.expire = 3600;
      state.group = [];
    },
    setFullName: (state, action) => ({
      ...state,
      name: action.payload.name,
      surname: action.payload.surname
    }),
    removeFullName: (state, action) => ({
      ...state,
      name: '',
      surname: ''
    })
  }
});

export const { setUserName, setUserGroup, setExpire, removeUserName, setFullName, removeFullName } =
  userSlice.actions;
export default userSlice.reducer;
