import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { useEffect } from 'react';
import { removeFullName, removeUserName } from 'sections/authentication/login/loginSlice';
import { useDispatch, useSelector } from 'react-redux';

const BoxStyle = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center'
});

export default function Logout() {
  const user = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    setInterval(() => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user_id');
      dispatch(removeUserName(user.username));
      dispatch(removeFullName());
      window.location.href = '/login';
    }, 1000);
  }, []);

  return (
    <BoxStyle>
      <CircularProgress />
      <Typography>Logging out...</Typography>
    </BoxStyle>
  );
}
