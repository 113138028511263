import { useEffect, useState } from 'react';
import { MenuItem, Menu, Box, ListItemIcon } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ApiGetSessions, ApiUpdateObjectPublish } from 'api';
import {
  addNewVideoPreviewList,
  addNewVideoPublishList
} from 'sections/@dashboard/app/videoListSlice';
import { PublishSectionIcon } from './icon/DashboardIcon';

export default function PublishMenu(props) {
  const {
    children,
    objectId,
    objectName,
    objectAmount,
    objectLocation,
    objectDate,
    objectThumbnail,
    objectFavourite,
    objectPublish,
    objectSessionId
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [items, setItems] = useState([]);
  const { publishList } = useSelector((state) => state.videoListSlice);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (ev) => {
    setAnchorEl(null);
    const sessionId = ev.currentTarget.getAttribute('id');
    const sessionName = ev.currentTarget.getAttribute('value');

    for (let i = 0; i < publishList.length; i += 1) {
      if (publishList[i].section === sessionName) {
        ApiUpdateObjectPublish(publishList[i].id, null, false, true)
          .then((response) => {
            const publishBeSubstitute = publishList.find((x) => x.id === publishList[i].id);
            dispatch(
              addNewVideoPreviewList({
                ...publishBeSubstitute,
                favourite: true,
                publish: false,
                session_id: null
              })
            );
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
    ApiUpdateObjectPublish(objectId, sessionId, true)
      .then((response) => {
        dispatch(
          addNewVideoPublishList({
            id: objectId,
            name: objectName,
            amount: objectAmount,
            location: objectLocation,
            date: objectDate,
            thumbnail: objectThumbnail,
            favourite: objectFavourite,
            publish: objectPublish,
            session_id: objectSessionId,
            section: sessionName
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    ApiGetSessions()
      .then((response) => {
        setItems(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div>
      <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
        {children}
      </Box>
      <Menu
        id="publish-menu"
        anchorEl={anchorEl}
        placement="bottom-start"
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'publish-button'
        }}
        PaperProps={{
          sx: { maxHeight: 250 }
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={handleSelect}
            sx={{
              minWidth: '150px',
              fontSize: '16px',
              '&:hover': {
                backgroundColor: 'rgba(42, 170, 226, 0.08) !important',
                color: '#2AAAE2;'
              }
            }}
            value={item.name}
            id={item.id}
            color="primary"
          >
            <ListItemIcon>
              <PublishSectionIcon />
            </ListItemIcon>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
