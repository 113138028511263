import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, Button, Avatar, Box, Grid, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { CognitoUserFactory } from 'utils/CognitoFactory';
import { ApiUpdateAuthDetail } from 'api';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import account from '../../../_mocks_/account';
import { setEmailForgotPassword } from '../forgotPassword/forgotPasswordSlice';
import { setFullName } from '../login/loginSlice';
// API
// ----------------------------------------------------------------------

export default function EditProfileForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice);
  const [name, setName] = useState(user.name);
  const [surname, setSurname] = useState(user.surname);
  const MySwal = withReactContent(Swal);

  const handleChangePassword = async () => {
    const cognitoUser = CognitoUserFactory(user.username);
    dispatch(setEmailForgotPassword(user.username));

    cognitoUser.forgotPassword({
      onSuccess(result) {
        navigate('confirmationCode', { replace: true });
      },
      onFailure(err) {
        alert(err);
      }
    });
  };

  const ProfileSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    surname: Yup.string().required('Surname is required')
  });

  const handleUpdateProfile = async () => {
    await ApiUpdateAuthDetail({ firstName: name, lastName: surname });
    dispatch(setFullName({ name, surname }));
    await MySwal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Profile data has been changed!'
    });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: ''
    },
    validationSchema: ProfileSchema,
    onSubmit: async () => {}
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Box sx={{ px: 2.5, py: 3, textAlign: 'center' }}>
                <Avatar src={account.photoURL} alt="photoURL" sx={{ width: 120, height: 120 }} />
              </Box>
            </Grid>
          </Grid>

          <TextField
            required
            {...getFieldProps('name')}
            value={name}
            onChange={(ev) => setName(ev.target.value)}
            label="Name"
          />

          <TextField
            required
            {...getFieldProps('surname')}
            value={surname}
            label="Surname"
            onChange={(ev) => setSurname(ev.target.value)}
          />

          <Button variant="contained" size="large" onClick={handleUpdateProfile}>
            Confirm
          </Button>
          <br />
          <Button
            variant="outlined"
            size="large"
            onClick={handleChangePassword}
            sx={{ textTransform: 'unset' }}
          >
            Change password
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
