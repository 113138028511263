import { useNavigate } from 'react-router-dom';
// material
import { Box, Collapse, Link, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { CognitoUserFactory } from 'utils/CognitoFactory';
import PropTypes from 'prop-types';
import { setCodeForgotPassword } from '../forgotPassword/forgotPasswordSlice';
// ----------------------------------------------------------------------

const OTPBoxStyle = {
  width: '3rem',
  height: '3rem',
  margin: '0 1rem',
  borderRadius: '8px',
  border: '1px solid rgba(145, 150, 171, 0.32)',
  fontSize: '18px',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: '24px',
  fontFamily: 'Plus Jakarta Sans'
};

ConfirmationCodeForm.propTypes = {
  navigateTo: PropTypes.string,
  topic: PropTypes.string
};

export default function ConfirmationCodeForm({ topic, navigateTo }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.forgotPasswordSlice);
  const cognitoUser = CognitoUserFactory(user.email);
  const [timeLeft, setTimeLeft] = useState(0);
  const [showTimeLeft, setShowTimeLeft] = useState(false);
  const [otp, setOtp] = useState();

  const handleResendCode = () => {
    cognitoUser.forgotPassword({
      onSuccess(result) {
        setTimeLeft(300);
        setShowTimeLeft(true);
      },
      onFailure(err) {
        console.error(err);
      }
    });
  };

  //   useEffect(() => {
  //     const intervalId = setInterval(() => {
  //       setTimeLeft((t) => t - 1);
  //     }, 1000);
  //     if (timeLeft === 0) {
  //       setShowTimeLeft(false);
  //     }
  //     return () => clearInterval(intervalId);
  //   }, [timeLeft]);

  const handleChange = async (val) => {
    setOtp(val);
    if (val.length === 6) {
      dispatch(setCodeForgotPassword(val));

      navigate(navigateTo, { replace: true });
    }
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          {topic}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          We've emailed a 6-digit confirmation code, please enter the code in the below box to
          verify your email.
        </Typography>
      </Box>

      <Stack spacing={3}>
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          shouldAutoFocus
          isInputNum
          inputStyle={OTPBoxStyle}
          name="code"
          id="code"
        />
      </Stack>

      <Collapse in={!showTimeLeft}>
        <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
          Don't have a code?
          <Link
            underline="hover"
            sx={{ ml: 1 }}
            onClick={handleResendCode}
            style={{ cursor: 'pointer', color: '#2AAAE2' }}
          >
            Resend code
          </Link>
        </Typography>
      </Collapse>
      <Collapse in={showTimeLeft}>
        <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
          You can request for resend a code again in {timeLeft} s
        </Typography>
      </Collapse>
    </>
  );
}
