import { useEffect, useState } from 'react';
// material
import { Container, Typography, Grid, Box, Tabs, Tab, Chip } from '@mui/material';
// components
import { TabContext, TabPanel } from '@mui/lab';
import { Navigate } from 'react-router-dom';
import Page from 'components/Page';
import {
  UploadFolderIcon,
  AnalysisProcessIcon,
  LogIcon,
  ClockIcon
} from 'components/icon/DashboardIcon';
import { useDispatch, useSelector } from 'react-redux';
import HorizontalLinearStepper from 'sections/@dashboard/video/HorizontalLinearStepper';
import VideoLoading from 'sections/@dashboard/video/VideoLoading';
import VideoLog from 'sections/@dashboard/video/VideoLog';
import VideoAnalysisProcess from 'sections/@dashboard/video/VideoAnalysisProcess';
import { ApiGetProcessingObjects, ApiUploadToS3, ApiGetPresignedUpload } from 'api';
import {
  removeVideoFile,
  updateVideoProgress,
  updateVideoStatus
} from 'sections/@dashboard/video/videoUploadSlice';
import jwtDecode from 'jwt-decode';
import { activateRefreshToken } from 'utils/CognitoFactory';
import { setExpire } from 'sections/authentication/login/loginSlice';

// ----------------------------------------------------------------------

export default function Video() {
  const [tab, setTab] = useState('Upload');
  const [title, setTitle] = useState('Add video');
  const [processingLength, setProcessingLength] = useState(0);
  const [uploadQueue, setUploadQueue] = useState([]);
  const [reUploadQueue, setReUploadQueue] = useState([]);
  const [triggerUpload, setTriggerUpload] = useState(false);
  const [uploadingId, setUploadingId] = useState('');

  const filesLoading = useSelector((state) => state.videoUploadSlice);
  const filesProcessing = useSelector((state) => state.videoProcessSlice);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice);
  const userGroup = user.group[0];

  useEffect(() => {
    ApiGetProcessingObjects('?filter.status=$in:pending,failed,processing').then(
      async (response) => {
        setProcessingLength(response.data.meta.totalItems);
      }
    );
  }, [tab, filesProcessing]);

  useEffect(async () => {
    if (uploadQueue.length > 0) {
      if (
        uploadingId !== uploadQueue[0].id &&
        filesLoading.findIndex((x) => x.status === 'processing') === -1
      ) {
        setUploadingId(uploadQueue[0].id);
        dispatch(updateVideoStatus({ id: uploadQueue[0].id, status: 'processing' }));
        const progressUpdate = async (progress) => {
          dispatch(updateVideoProgress({ id: uploadQueue[0].id, progress }));
          //   await activateRefreshToken(
          //     jwtDecode(localStorage.getItem('access_token')).username,
          //     (e) => dispatch(setExpire(e))
          //   );
        };

        const presignURL = await ApiGetPresignedUpload({
          uploadId: uploadQueue[0].uploadId,
          path: uploadQueue[0].path,
          filename: uploadQueue[0].filename
        }).catch((error) => {
          console.error(error.response);
        });

        const responseS3 = await ApiUploadToS3(
          presignURL.data,
          uploadQueue[0].data,
          progressUpdate,
          uploadQueue[0].controller
        ).catch((err) => {
          console.error(err);
          if (err.message === 'canceled') {
            dispatch(removeVideoFile({ id: uploadQueue[0].id }));
          } else {
            dispatch(updateVideoStatus({ id: uploadQueue[0].id, status: 'failed' }));
            setReUploadQueue((prevState) => [...prevState, uploadQueue[0]]);
          }
          setUploadQueue((prevState) => prevState.slice(1));
          setUploadingId('');
        });
        console.log('ApiUploadToS3 responseS3', responseS3);
        if (responseS3) {
          if (responseS3.status === 200) {
            dispatch(removeVideoFile({ id: uploadQueue[0].id }));
            setUploadQueue((prevState) => prevState.slice(1));
          }
        }
      }
    }
  }, [triggerUpload]);

  useEffect(async () => {
    if (uploadQueue.length > 0) {
      setTriggerUpload((prevState) => !prevState);
    }
  }, [uploadQueue]);

  if (!userGroup) {
    return <Navigate to="/401" replace />;
  }
  if (!userGroup.includes('Master')) {
    return <Navigate to="/401" replace />;
  }

  const handleTab = (event, newValue) => {
    setTab(newValue);
    if (newValue === 'Upload') {
      newValue = 'Add video';
    }
    setTitle(newValue);
  };

  return (
    <Page title="Video | Aquatic monitoring system">
      <Container sx={{ marginLeft: 0 }} maxWidth={false}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          {title}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={tab}>
                <Box>
                  <Tabs
                    value={tab}
                    onChange={handleTab}
                    aria-label="report export"
                    textColor="inherit"
                  >
                    <Tab
                      icon={<UploadFolderIcon width={30} />}
                      iconPosition="start"
                      label={<Typography variant="subtitle2">Upload</Typography>}
                      value="Upload"
                    />
                    <Tab
                      icon={<ClockIcon width={30} height={20} />}
                      iconPosition="start"
                      wrapped
                      label={
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          columnSpacing={1}
                        >
                          <Grid item>
                            <Typography variant="subtitle2">Loading</Typography>
                          </Grid>
                          <Grid item>
                            <Chip
                              label={
                                filesLoading.filter((item) => item.status !== 'success').length
                              }
                              variant="outlined"
                              sx={{
                                color: 'red',
                                borderRadius: '10px',
                                border: '1px solid #EC2127'
                              }}
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      }
                      value="Loading"
                    />
                    <Tab
                      icon={<AnalysisProcessIcon width={30} />}
                      iconPosition="start"
                      label={
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          columnSpacing={1}
                        >
                          <Grid item>
                            <Typography variant="subtitle2">Analysing</Typography>
                          </Grid>
                          <Grid item>
                            <Chip
                              label={processingLength}
                              variant="outlined"
                              sx={{
                                color: 'red',
                                borderRadius: '10px',
                                border: '1px solid #EC2127'
                              }}
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      }
                      value="Analysing"
                    />
                    <Tab
                      icon={<LogIcon width={30} />}
                      iconPosition="start"
                      label={<Typography variant="subtitle2">Log</Typography>}
                      value="Log"
                    />
                  </Tabs>
                </Box>
                <TabPanel value="Upload">
                  <HorizontalLinearStepper
                    setTab={setTab}
                    setTitle={setTitle}
                    setUploadQueue={setUploadQueue}
                  />
                </TabPanel>
                <TabPanel value="Loading">
                  <VideoLoading
                    uploadQueue={uploadQueue}
                    setUploadQueue={setUploadQueue}
                    reUploadQueue={reUploadQueue}
                    setReUploadQueue={setReUploadQueue}
                  />
                </TabPanel>
                <TabPanel value="Analysing">
                  <VideoAnalysisProcess />
                </TabPanel>
                <TabPanel value="Log">
                  <VideoLog />
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
