import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from 'layouts/dashboard';
import SessionLayout from 'layouts/SessionLayout';

//
import Login from 'pages/Login';
import DashboardApp from 'pages/DashboardApp';
import Video from 'pages/Video';
import NotFound from 'pages/Page404';
import Unauthorized from 'pages/Page401';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import ConfirmationCode from 'pages/ConfirmationCode';
import FishClassification from 'pages/FishClassification';
import Profile from 'pages/Profile';
import ProfileConfirmationCode from 'pages/Profile/confirmationCode';
import ProfileResetPassword from 'pages/Profile/resetPassword';
import Logout from './pages/Public/logout';
import PublicLayout from './layouts/PublicLayout';

// ----------------------------------------------------------------------

const dashboardRoute = {
  path: '/dashboard',
  element: <DashboardLayout />,
  children: [
    { path: 'app', element: <DashboardApp /> },
    { path: 'label', element: <FishClassification /> },
    { path: 'video', element: <Video /> },
    { path: 'profile', element: <Profile /> },
    { path: 'profile/confirmationCode', element: <ProfileConfirmationCode /> },
    { path: 'profile/resetPassword', element: <ProfileResetPassword /> }
  ]
};

const sessionRoute = {
  path: '/',
  element: <SessionLayout />,
  children: [
    { path: '/', element: <Navigate to="/login" /> },
    { path: 'login', element: <Login /> },
    { path: 'forgotPassword', element: <ForgotPassword /> },
    { path: 'confirmationCode', element: <ConfirmationCode /> },
    { path: 'resetPassword', element: <ResetPassword /> }
  ]
};

const publicRoute = {
  path: '/public',
  element: <PublicLayout />,
  children: [{ path: 'logout', element: <Logout /> }]
};

export default function Router() {
  return useRoutes([
    sessionRoute,
    dashboardRoute,
    publicRoute,
    { path: '404', element: <NotFound /> },
    { path: '401', element: <Unauthorized /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
