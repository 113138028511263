import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Button, Typography, Container, styled } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from 'components/animate';
import Page from 'components/Page';
import { useSelector } from 'react-redux';
import Logo from 'components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Page404() {
  const user = useSelector((state) => state.userSlice);
  const userGroup = user.group[0];

  return (
    <RootStyle title="404 Page Not Found | Aquatic monitoring system">
      <Container>
        <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
          <Logo
            sx={{
              width: 60 * 1.085,
              height: 60
            }}
          />
        </Box>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 600, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Sorry, page not found!
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL?
              Be sure to check your spelling.
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/illus_404_update.png"
                sx={{ height: 430, mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
            </motion.div>

            {userGroup ? (
              <Button to="/dashboard/app" size="large" variant="contained" component={RouterLink}>
                Go to Dashboard
              </Button>
            ) : (
              <Button to="/" size="large" variant="contained" component={RouterLink}>
                Go to Login Page
              </Button>
            )}
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
