import { CardHeader, styled, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell, Sector } from 'recharts';
import { useEffect, useState } from 'react';
import PieTypeChartTooltip from 'layouts/dashboard/PieTypeChartTooltip';
import PieTypeChartLegend from 'layouts/dashboard/PieTypeChartLegend';
import breakpoints from 'theme/breakpoints';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5)
}));

// ----------------------------------------------------------------------

AppTypePieCharts.propTypes = {
  drillDown: PropTypes.func
};

export default function AppTypePieCharts({ drillDown, type, setType, typeList, setTypeId }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  for (let index = 0; index < typeList.length; index += 1) {
    typeList[index].amount = Number(typeList[index].amount);
  }
  const COLORS = [
    'rgba(31,82,186)',
    'rgba(88,159,211)',
    'rgba(86,188,235)',
    'rgba(131,207,255)',
    'rgba(165,243,255)',
    'rgba(167,215,246)',
    'rgba(176,223,229)',
    'rgba(204,250,255)',
    'rgba(149,200,217)',
    'rgba(137,208,240)',
    'rgba(129,216,208)',
    'rgba(32,91,170)',
    'rgba(35,62,153)',
    'rgba(52,43,133)',
    'rgba(52,43,133)',
    'rgba(52,43,133)'
  ];
  const COLORS_INACTIVE = Array.from(
    COLORS,
    (item) => `${item.substring(0, item.length - 1)}, 0.5)`
  );
  const [colorsSet, setColorsSet] = useState(COLORS);
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const isBetween = index < 4 || index > 8;
    return (
      <text
        x={x}
        y={y}
        fill={
          // eslint-disable-next-line no-nested-ternary
          isBetween
            ? 'white'
            : // eslint-disable-next-line no-nested-ternary
            activeIndex === -1
            ? 'black'
            : index === activeIndex
            ? 'black'
            : '#919EAB'
        }
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontSize: '14px', pointerEvents: 'none' }}
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    );
  };

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={`${fill.substring(0, fill.length - 6)})`}
        />
      </g>
    );
  };

  const onMouseClick = (data, index) => {
    if (type === typeList[index].name) {
      typeSettingHelper('All types', 'All');
    } else {
      typeSettingHelper(typeList[index].name, typeList[index].specie_id);
    }
  };

  const typeSettingHelper = (typeSetter, typeIdSetter) => {
    setTypeId(typeIdSetter);
    setType(typeSetter);
  };

  useEffect(() => {
    let findIndex = -1;
    for (let index = 0; index < typeList.length; index += 1) {
      if (typeList[index].name === type) {
        findIndex = index;
      }
    }
    setActiveIndex(findIndex);

    if (type !== 'All types') {
      drillDown(true);
      setColorsSet(COLORS_INACTIVE);
    } else {
      drillDown(false);

      setColorsSet(COLORS);
    }
  }, [type]);

  useEffect(() => {
    const onResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [setWidth]);

  return (
    <>
      <CardHeader title="Classified aquatic animals" />
      <ChartWrapperStyle dir="ltr">
        <ResponsiveContainer width="90%" height="100%" minWidth="200px">
          <PieChart height={400} onMouseLeave={() => setShowTooltip(false)}>
            <Pie
              dataKey="amount"
              isAnimationActive={false}
              data={typeList}
              cx="50%"
              cy="50%"
              outerRadius={140}
              labelLine={false}
              // label={renderCustomizedLabel}
              activeShape={renderActiveShape}
              activeIndex={activeIndex}
              onClick={onMouseClick}
              onMouseEnter={() => setShowTooltip(true)}
            >
              {typeList.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colorsSet[index % colorsSet.length]} />
              ))}
            </Pie>
            <Legend
              verticalAlign={width > breakpoints.values.sm ? 'middle' : 'bottom'}
              align={width > breakpoints.values.sm ? 'right' : 'center'}
              layout={width > breakpoints.values.sm ? 'vertical' : 'horizontal'}
              iconType="circle"
              content={
                <PieTypeChartLegend
                  type={type}
                  typeList={typeList}
                  setType={setType}
                  setTypeId={setTypeId}
                  isSm={width < breakpoints.values.sm}
                />
              }
              wrapperStyle={{
                left: width > breakpoints.values.sm ? 'calc(100% *2/3)' : 'calc(100% *1/8)',
                fontSize: width < breakpoints.values.sm && '13px'
              }}
            />
            {showTooltip && (
              <Tooltip
                sAnimationActive={false}
                content={<PieTypeChartTooltip setShowTooltip={setShowTooltip} />}
                wrapperStyle={{ visibility: 'visible', pointerEvents: 'auto' }}
              />
            )}
          </PieChart>
        </ResponsiveContainer>
      </ChartWrapperStyle>
    </>
  );
}
