import PropTypes from 'prop-types';
// material
import { styled, Card, Stack, Typography } from '@mui/material';
// components
import Logo from 'components/Logo';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(2, 5, 0, 7)
  }
}));

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  children: PropTypes.node
};

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: '#2AAAE2',
  color: 'white'
}));

export default function AuthLayout({ children }) {
  return (
    <>
      <HeaderStyle>
        <Logo
          sx={{
            backgroundColor: 'white',
            width: 80 * 1.085,
            height: 80,
            borderRadius: '0px 0px 15px 15px'
          }}
        />

        <Typography
          variant="body2"
          sx={{
            display: { xs: 'none', sm: 'block' },
            mt: { md: -2 }
          }}
        >
          {children}
        </Typography>
      </HeaderStyle>
      <SectionStyle sx={{ display: { xs: 'none', md: 'flex', textAlign: 'center' } }}>
        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
          Hi, Welcome back
        </Typography>
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
          <img src="/static/illustrations/whale_v2.png" alt="login" width={350} />
        </Stack>
      </SectionStyle>
    </>
  );
}
