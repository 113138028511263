import { Typography, Box, styled, Card } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setNewVideoPlaying } from 'sections/@dashboard/app/videoPlayingSlice';
import { updateUsePreviewList } from 'sections/@dashboard/app/videoListSlice';
import { fDateTimeNoMillisec } from 'utils/formatTime';

const TypographyStyled = styled(Typography)({
  fontSize: '14px',
  color: '#637381'
});

const CardShadowStyle = styled(Card)(({ theme }) => ({
  boxShadow: '0px 16px 32px -4px rgba(145, 158, 171, 0.12)'
}));

export default function VideoPublishThumbnail(props) {
  const { section, name, location, date, image, handleOpenVideoModal, id } = props;

  const { publishList } = useSelector((state) => state.videoListSlice);

  const handleShowVideoPlayer = (id) => {
    const index = publishList.findIndex((x) => x.id === id);
    dispatch(setNewVideoPlaying({ playing: publishList[index] }));
    dispatch(updateUsePreviewList(false));
    handleOpenVideoModal();
  };
  const dispatch = useDispatch();

  return (
    <CardShadowStyle
      sx={{ width: '344px', height: '332px', cursor: 'pointer' }}
      onClick={() => handleShowVideoPlayer(id)}
    >
      <img src={image || '/static/swarm_of_fish.jpeg'} alt={name} width="344" height="200" />
      <Box sx={{ padding: '10px' }}>
        <Typography variant="subtitle2">{section}</Typography>
        <TypographyStyled variant="body2">Name: {name}</TypographyStyled>
        <TypographyStyled variant="body2">Location: {location}</TypographyStyled>
        <TypographyStyled variant="body2">
          Publish date: {fDateTimeNoMillisec(date)}
        </TypographyStyled>
      </Box>
    </CardShadowStyle>
  );
}
