// material
import { Stack, Grid, Typography, styled, alpha, Box } from '@mui/material';
//
import { fNumber } from 'utils/formatNumber';
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
  YAxis
} from 'recharts';
import { fDateOnly } from 'utils/formatTime';

// ----------------------------------------------------------------------

const TooltipStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1),
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
  background: 'rgba(255, 255, 255, 0.48)',
  borderRadius: '4px',
  boxShadow: '0 1px 4px rgba(0,0,0,0.3)'
}));

function CustomTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    return (
      <Box>
        <TooltipStyle>
          <Typography>
            {label.replace(/(..).(..).(....)/, '$2/$1/$3')} :{' '}
            <span style={{ fontWeight: 'bold' }}>{payload[0].value}</span>
          </Typography>
        </TooltipStyle>
      </Box>
    );
  }

  return null;
}

export default function AppClassifiedChart({ isDrillDown, classified, total, numberByDate }) {
  const donutData = [
    { name: 'confidence', value: Number(classified) },
    { name: 'non-confidence', value: Number(total - classified) }
  ];

  for (let index = 0; index < numberByDate.length; index += 1) {
    numberByDate[index].amount = Number(numberByDate[index].amount);
    numberByDate[index].date = fDateOnly(numberByDate[index].date);
  }
  const COLORS = ['#252260', 'rgba(0.15, 0.08, 0.00, 0.14)'];

  return (
    <Stack
      height="220px"
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      sx={{ margin: '0 auto' }}
    >
      {isDrillDown ? (
        <ResponsiveContainer width="100%" height="80%">
          <LineChart
            width={400}
            height={250}
            data={numberByDate}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
            style={{ marginTop: '30px' }}
          >
            <CartesianGrid vertical={false} horizontal strokeDasharray="3 3" />
            {/* eslint-disable-next-line react/no-unstable-nested-components */}
            <YAxis tickCount={4} tickLine={false} axisLine={false} tick={() => <div />} />
            <XAxis dataKey="date" label="Number by date" axisLine={false} tick={false} />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="amount"
              stroke="rgba(145,158,171,0.3)"
              strokeWidth={3}
              dot={false}
              activeDot={{ stroke: '#252260', strokeWidth: 2, r: 4, fill: '#252260' }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <>
          <ResponsiveContainer width={200} height="100%">
            <PieChart width={300} height={300}>
              <Pie
                data={donutData}
                cx="50%"
                cy="50%"
                innerRadius={35}
                outerRadius={45}
                fill="#8884d8"
                dataKey="value"
                startAngle={90}
                endAngle={-270}
              >
                {donutData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                <Label value={`${fNumber((classified / total) * 100)}%`} position="center" />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <Grid container direction="column">
            <Typography variant="h3">{fNumber(donutData[0].value)}</Typography>
            <ul>
              <li style={{ color: '#252260' }}>
                <Typography variant="body2" sx={{ color: 'black' }}>
                  Classified
                </Typography>
              </li>
              <li style={{ color: 'rgba(145, 158, 171, 0.16)' }}>
                <Typography variant="body2" sx={{ color: 'black' }}>
                  Unknown
                </Typography>
              </li>
            </ul>
          </Grid>
        </>
      )}
    </Stack>
  );
}
