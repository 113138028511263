import { createSlice } from '@reduxjs/toolkit';

const videoPlayingSlice = createSlice({
  name: 'videoPlayingSlice',
  initialState: {
    playing: {}
  },
  reducers: {
    setNewVideoPlaying: (state, action) => {
      state.playing = action.payload.playing;
    }
  }
});

export const { setNewVideoPlaying } = videoPlayingSlice.actions;
export default videoPlayingSlice.reducer;
