import { CardActionArea, Card, CardContent, CardMedia, Typography } from '@mui/material';
import PropTypes from 'prop-types';

AreaCard.propTypes = {
  src: PropTypes.string,
  text: PropTypes.string
};

export default function AreaCard({ src, text }) {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia component="img" height="140" image={src} alt={text} />
        <CardContent>
          <Typography gutterBottom variant="subtitle1" component="div">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
