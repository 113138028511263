import {
  Box,
  Button,
  Typography,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer
} from '@mui/material';
import { useState } from 'react';
import Iconify from 'components/Iconify';
import { fDateTime } from 'utils/formatTime';
import { ApiDeleteLocation, ApiNewLocation } from 'api';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import TextEditField from './TextEditField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '2%',
  boxShadow: 24,
  p: 4
};

export default function NewLocationModal({
  rows,
  setRows,
  newLocation,
  setNewLocation,
  columns,
  setLocation
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const MySwal = withReactContent(Swal);

  const handleDialogOpen = async (event) => {
    const deleteValue = event.currentTarget.getAttribute('name');
    const result = await MySwal.fire({
      title: 'Are you sure ?',
      html: 'Do you really want to delete these ? <br>This process cannot be undone.',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      reverseButtons: true,
      confirmButtonColor: '#FF4842',
      iconHtml: '<img src="/static/cancelButton.png">',
      customClass: {
        icon: 'no-border'
      }
    });
    if (result.isConfirmed) {
      ApiDeleteLocation(deleteValue)
        .then((response) => {
          setRows(rows.filter((item) => item.id.toString() !== deleteValue));
          setLocation('');
        })
        .catch((error) => {
          if (error.response) {
            console.error(error.response.data);
          }
          MySwal.fire('Delete location failed');
        });
    }
  };

  const handleAddRow = () => {
    setNewLocation('');
    ApiNewLocation({ name: newLocation })
      .then((response) => {
        const newRows = {
          id: response.data.id,
          name: response.data.name,
          createdAt: response.data.createdAt
        };
        setRows([newRows, ...rows]);
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        }
      });
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="text"
        sx={{ textDecoration: 'underline', color: 'black' }}
      >
        ADD NEW LOCATION
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: '20px', border: 0 }}
      >
        <Box sx={style}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Iconify icon="iconoir:cancel" onClick={handleClose} sx={{ cursor: 'pointer' }} />
          </Grid>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Locations
          </Typography>
          <FormControl sx={{ mt: 2 }}>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <TextField
                  label="Location name"
                  sx={{ minWidth: '250px' }}
                  value={newLocation}
                  onChange={(ev) => setNewLocation(ev.target.value)}
                />
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleAddRow}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </FormControl>
          <TableContainer sx={{ maxHeight: 440, mt: 3 }}>
            <Table aria-label="Location table">
              <colgroup>
                <col style={{ width: '50%' }} />
                <col style={{ width: '40%' }} />
                <col style={{ width: '10%' }} />
              </colgroup>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#F4F6F8' }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color: '#637381' }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'name' ? (
                            <TextEditField
                              type="text"
                              value={value}
                              key={row.id}
                              id={row.id}
                              rows={rows}
                              setRows={setRows}
                            />
                          ) : (
                            <div>{fDateTime(value)}</div>
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell key={row.id} name={row.id}>
                      {/* <Box onClick={handleDialogOpen} name={row.id} sx={{ cursor: 'pointer' }}> */}
                      {/*  <BinIcon name={row.id} color="#637381" /> */}
                      {/* </Box> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
