import { createSlice } from '@reduxjs/toolkit';

const videoProcessSlice = createSlice({
  name: 'videoProcessSlice',
  initialState: [],
  reducers: {
    setNewProcessFile: (state, action) => [...state, action.payload],
    removeAllProcessFile: (state, action) => [],
    removeProcessFile: (state, action) => {
      const index = state.findIndex((x) => action.payload.id === x.id);
      if (index === -1) {
        return [...state];
      }
      return [...state.slice(0, index), ...state.slice(index + 1)];
    },
    updateProcessProgress: (state, action) => {
      const index = state.findIndex((x) => action.payload.id === x.id);
      if (index === -1) {
        return [...state];
      }

      return [
        ...state.slice(0, index),
        { ...state[index], processing: action.payload.progress },
        ...state.slice(index + 1)
      ];
    },
    updateProcessStatus: (state, action) => {
      const index = state.findIndex((x) => action.payload.id === x.id);
      if (index === -1) {
        return [...state];
      }
      return [
        ...state.slice(0, index),
        { ...state[index], status: action.payload.status },
        ...state.slice(index + 1)
      ];
    }
  }
});

export const {
  setNewProcessFile,
  removeAllProcessFile,
  removeProcessFile,
  updateProcessProgress,
  updateProcessStatus
} = videoProcessSlice.actions;
export default videoProcessSlice.reducer;
