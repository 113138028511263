import TableUploadLayout from './TableUploadLayout';

const columns = [
  { id: 'name', label: 'Name', width: '25%' },
  {
    id: 'date',
    label: 'Upload date',
    width: '18%'
  },
  { id: 'locationName', label: 'Location', width: '25%' },
  {
    id: 'startDate',
    label: 'Start date / time',
    width: '17%'
  }
];

export default function VideoLog() {
  return (
    <TableUploadLayout
      columns={columns}
      withProgressBar={false}
      srcKey="status"
      operator="==="
      compareValue="completed"
    />
  );
}
