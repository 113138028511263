import React, { useState } from 'react';
import { Typography, Box, styled, Stack, Input } from '@mui/material';
import Iconify from 'components/Iconify';
import { ApiUpdateObject } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { setNewVideoPlaying } from 'sections/@dashboard/app/videoPlayingSlice';
import { pluralize } from 'utils/formatString';
import {
  updateUsePreviewList,
  updatePublishListName
} from 'sections/@dashboard/app/videoListSlice';
import { fDateTimeNoMillisec } from 'utils/formatTime';
import PublishMenu from './PublishMenu';
import FavouriteIconRender from './FavouriteIconRender';

const TypographyStyled = styled(Typography)({
  fontSize: '14px',
  color: '#637381'
});

export default function VideoPreviewThumbnail(props) {
  const {
    showPublish,
    name,
    amount = 0,
    location,
    date,
    image,
    favourite,
    publish,
    sessionId,
    handleOpenVideoModal,
    id
  } = props;

  const { previewList } = useSelector((state) => state.videoListSlice);

  const [isEdit, setIsEdit] = useState(false);
  const [fileName, setFileName] = useState(props.name);

  const handleShowVideoPlayer = (id) => {
    const index = previewList.findIndex((x) => x.id === id);
    dispatch(setNewVideoPlaying({ playing: previewList[index] }));
    dispatch(updateUsePreviewList(true));
    handleOpenVideoModal();
  };

  const handleChangeVideoName = async (id, name) => {
    setIsEdit(!isEdit);

    if (isEdit) {
      await ApiUpdateObject(id, { name: fileName }).catch((err) => console.error(err));
      dispatch(updatePublishListName({ id, name: fileName }));
    }
  };

  const renderName = () => (
    <div>
      {!isEdit ? (
        <>
          {fileName}
          <Iconify
            icon="bi:pencil-square"
            sx={{ ml: 1, cursor: 'pointer' }}
            width={14}
            height={14}
            onClick={() => setIsEdit(!isEdit)}
          />
        </>
      ) : (
        <>
          <Input
            autoFocus
            value={fileName}
            sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            onChange={(e) => setFileName(e.target.value)}
          />
          <Iconify
            icon="bi:pencil-square"
            sx={{ ml: 1, cursor: 'pointer' }}
            width={14}
            height={14}
            onClick={() => handleChangeVideoName(id, name)}
          />
        </>
      )}
    </div>
  );

  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        width: '252px',
        height: '290px',
        background: '#F9FAFB',
        boxShadow: '0px 16px 32px -4px rgba(145, 158, 171, 0.12)'
      }}
    >
      <Box onClick={() => handleShowVideoPlayer(id)} style={{ cursor: 'pointer' }}>
        <img
          src={image || '/static/swarm_of_fish.jpeg'}
          alt={name}
          width="236"
          height="141"
          style={{ borderRadius: '10px' }}
        />
      </Box>
      <Box sx={{ padding: '10px' }}>
        <Stack direction="row" spacing={3} flexShrink={0}>
          <FavouriteIconRender id={id} value={favourite} withText />
          {showPublish && (
            <PublishMenu
              objectId={id}
              objectName={name}
              objectAmount={amount}
              objectLocation={location}
              objectDate={date}
              objectThumbnail={image}
              objectFavourite={favourite}
              objectPublish={publish}
              objectSessionId={sessionId}
            >
              <TypographyStyled variant="body2" sx={{ mb: 1 }}>
                <Iconify icon="bi:share-fill" sx={{ mr: 1 }} />
                Publish
              </TypographyStyled>
            </PublishMenu>
          )}
        </Stack>
        {/* <Box onClick={() => handleShowVideoPlayer(id)} sx={{ cursor: 'pointer' }}> */}
        <Box>
          <Typography variant="subtitle2">{renderName()}</Typography>
          <TypographyStyled variant="body2">
            {amount} {pluralize(amount, 'Fish', 'es')}
          </TypographyStyled>
          <TypographyStyled variant="body2">{location}</TypographyStyled>
          <TypographyStyled variant="body2">{fDateTimeNoMillisec(date)}</TypographyStyled>
        </Box>
      </Box>
    </Box>
  );
}
