import React, { useState, useEffect } from 'react';
import { Typography, Box, styled, Stack, Input } from '@mui/material';
import { ApiUpdateObject } from 'api';
import { setNewVideoPlaying } from 'sections/@dashboard/app/videoPlayingSlice';
import { updatePublishListName } from 'sections/@dashboard/app/videoListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { pluralize } from 'utils/formatString';
import { fDateTimeNoMillisec } from 'utils/formatTime';
import Iconify from 'components/Iconify';
import FavouriteIconRender from './FavouriteIconRender';

const TypographyStyled = styled(Typography)({
  fontSize: '14px',
  color: '#637381'
});

export default function VideoPreviewThumbnailSide(props) {
  const { name, amount = 0, location, date, image, favourite, id } = props;

  const { previewList, publishList, usePreviewList } = useSelector((state) => state.videoListSlice);
  const dispatch = useDispatch();
  const listRender = usePreviewList ? previewList : publishList;
  const [isEdit, setIsEdit] = useState(false);
  const [fileName, setFileName] = useState(props.name);

  const handleClickVideoSideBar = (idClick) => {
    const index = listRender.findIndex((x) => x.id === idClick);
    dispatch(
      setNewVideoPlaying({
        playing: listRender[index]
      })
    );
  };

  const handleChangeVideoName = async (id, name) => {
    setIsEdit(!isEdit);

    if (isEdit) {
      await ApiUpdateObject(id, { name: fileName }).catch((err) => console.error(err));
      dispatch(updatePublishListName({ id, name: fileName }));
    }
  };

  const renderName = (id, name) => (
    <div>
      {!isEdit ? (
        <>
          {fileName || '-'}
          <Iconify
            icon="bi:pencil-square"
            sx={{ ml: 1, cursor: 'pointer' }}
            width={14}
            height={14}
            onClick={() => setIsEdit(!isEdit)}
          />
        </>
      ) : (
        <>
          <Input
            autoFocus
            value={fileName}
            sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            onChange={(e) => setFileName(e.target.value)}
          />
          <Iconify
            icon="bi:pencil-square"
            sx={{ ml: 1, cursor: 'pointer' }}
            width={14}
            height={14}
            onClick={() => handleChangeVideoName(id, name)}
          />
        </>
      )}
    </div>
  );

  return (
    <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <img
        src={image || '/static/swarm_of_fish.jpeg'}
        alt={name}
        width={99}
        height={99}
        style={{ borderRadius: 8 }}
        onClick={() => handleClickVideoSideBar(id)}
      />
      <Box>
        <Box onClick={() => handleClickVideoSideBar(id)}>
          <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
            {renderName(id, name)}
          </Typography>
          <TypographyStyled>
            {amount} {pluralize(amount, 'Fish', 'es')}
          </TypographyStyled>
          <TypographyStyled>{location}</TypographyStyled>
          <TypographyStyled>{fDateTimeNoMillisec(date)}</TypographyStyled>
        </Box>
        <FavouriteIconRender id={id} value={favourite} />
      </Box>
    </Stack>
  );
}
