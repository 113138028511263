import TableUploadLayout from './TableUploadLayout';

const columns = [
  { id: 'name', label: 'Name', width: '30%' },
  {
    id: 'date',
    label: 'Upload date',
    width: '20%'
  },
  { id: 'locationName', label: 'Location', width: '30%' }
];

export default function VideoAnalysisProcess() {
  return (
    <TableUploadLayout
      columns={columns}
      withProgressBar
      srcKey="processing"
      operator="<"
      compareValue={100}
    />
  );
}
