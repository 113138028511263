import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Collapse,
  Alert,
  Button
} from '@mui/material';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { useDispatch } from 'react-redux';

// component
import Iconify from 'components/Iconify';
// API
import { CognitoUserFactory } from 'utils/CognitoFactory';
import { ApiGetAuthDetail } from 'api';
import { LoadingButton } from '@mui/lab';
import { setExpire, setFullName, setUserGroup, setUserName } from './loginSlice';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(
    'Wrong credentials. Please verify the email and password used'
  );
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      setLoading(true);
      const { email: username, password } = formik.values;

      const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
        Username: username,
        Password: password
      });

      const cognitoUser = CognitoUserFactory(username);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          const accessToken = result.getAccessToken().getJwtToken();
          const tokenPayload = result.getAccessToken().decodePayload();
          const expiration = result.getAccessToken().getExpiration();
          const refreshToken = result.getRefreshToken().getToken();

          localStorage.setItem('access_token', accessToken);
          localStorage.setItem('refresh_token', refreshToken);
          dispatch(setUserName(tokenPayload.username));
          dispatch(setUserGroup(tokenPayload['cognito:groups']));
          dispatch(setExpire(expiration));

          const user = await ApiGetAuthDetail();
          dispatch(setFullName({ name: user.data.firstName, surname: user.data.lastName }));
          window.location.href = '/dashboard/app';
        },

        onFailure: (err) => {
          console.error('onFailure', err.message);
          if (err.message === 'Incorrect username or password.') {
            setErrMsg('Wrong credentials. Please verify the email and password used');
          } else {
            setErrMsg(err.message);
          }
          setShowAlert(true);
          setLoading(false);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          setErrMsg('password change required');
          setShowAlert(true);
        }
      });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>
        <Collapse in={showAlert}>
          <Alert
            onClose={() => {
              setShowAlert(false);
            }}
            severity="error"
            sx={{ mb: 2 }}
          >
            {errMsg}
          </Alert>
        </Collapse>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} to="/forgotPassword" underline="hover">
            Forgot password?
          </Link>
        </Stack>
        <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained">
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
