import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Link, Container, Typography, styled } from '@mui/material';
// components
import Page from 'components/Page';
import Iconify from 'components/Iconify';
import { ResetPasswordForm } from 'sections/authentication/resetPassword';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '10vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ProfileResetPassword() {
  return (
    <Page title="Change password | Aquatic monitoring system">
      <Container sx={{ marginLeft: 0 }}>
        <Box sx={{ mb: 2 }}>
          <Link
            variant="string"
            underline="none"
            component={RouterLink}
            to="/dashboard/profile/confirmationCode"
          >
            <Iconify icon="eva:arrow-ios-back-fill" />
            Back
          </Link>
        </Box>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Change password
        </Typography>
        <ContentStyle>
          <Typography variant="h6" sx={{ mb: 2 }}>
            New password <span style={{ color: 'red' }}>*</span>
          </Typography>
          <ResetPasswordForm navigateTo="/dashboard/profile" />
        </ContentStyle>
      </Container>
    </Page>
  );
}
