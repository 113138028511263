import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const maxSize = 5000000000;

export default function MyDropzone(props) {
  const { filesUpload, setFilesUpload, location, children } = props;
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      rejectedFiles.forEach((file) => {
        if (file.file.size > maxSize) {
          alert(`${file.file.name} is too large. Maximum upload file size is 5 GB.`);
        } else {
          alert(`${file.file.name} is not supported file type. Please upload mp4 file.`);
        }
      });
      const updatedArray = filesUpload.slice();
      acceptedFiles.forEach((file) => {
        updatedArray.push(file);
      });
      setFilesUpload(updatedArray);
    },
    [filesUpload, setFilesUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'video/mp4': []
    },
    maxSize,
    onDrop
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
}
