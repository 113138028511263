// material
import { Button, Box } from '@mui/material';

export default function LogoutButton() {
  const handleLogOut = () => {
    window.location.href = '/public/logout';
  };

  return (
    <Box sx={{ p: 2, pt: 1.5 }}>
      <Button fullWidth variant="outlined" color="greytone" onClick={() => handleLogOut()}>
        Logout
      </Button>
    </Box>
  );
}
