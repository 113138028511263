import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx, width = 80 * 1.085, height = 80 }) {
  return (
    <Box
      component="img"
      src="/static/PTTEP-logo.png"
      sx={{
        width,
        height,
        ...sx
      }}
    />
  );
}
