import { createSlice } from '@reduxjs/toolkit';

const videoUploadSlice = createSlice({
  name: 'videoUploadSlice',
  initialState: [],
  reducers: {
    setNewVideoFile: (state, action) => [action.payload, ...state],
    removeAllVideoFile: (state, action) => [],
    removeVideoFile: (state, action) => {
      const index = state.findIndex((x) => action.payload.id === x.id);
      if (index === -1) {
        return [...state];
      }
      return [...state.slice(0, index), ...state.slice(index + 1)];
    },
    updateVideoProgress: (state, action) => {
      const index = state.findIndex((x) => action.payload.id === x.id);
      if (index === -1) {
        return [...state];
      }
      return [
        ...state.slice(0, index),
        { ...state[index], progress: action.payload.progress },
        ...state.slice(index + 1)
      ];
    },
    updateVideoStatus: (state, action) => {
      const index = state.findIndex((x) => action.payload.id === x.id);
      if (index === -1) {
        return [...state];
      }
      return [
        ...state.slice(0, index),
        { ...state[index], status: action.payload.status },
        ...state.slice(index + 1)
      ];
    }
  }
});

export const {
  setNewVideoFile,
  removeAllVideoFile,
  removeVideoFile,
  updateVideoProgress,
  updateVideoStatus
} = videoUploadSlice.actions;
export default videoUploadSlice.reducer;
