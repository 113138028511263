import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import CONFIG from 'config';
import jwtDecode from 'jwt-decode';

export const CognitoPoolFactory = () => {
  const UserPoolId = CONFIG.getValue('COGNITO_USER_POOL_ID');
  const ClientId = CONFIG.getValue('COGNITO_CLIENT_ID');

  const poolData = {
    UserPoolId,
    ClientId
  };

  return new AmazonCognitoIdentity.CognitoUserPool(poolData);
};

export const CognitoUserFactory = (userEmail) => {
  const userData = {
    Username: userEmail,
    Pool: CognitoPoolFactory()
  };

  return new AmazonCognitoIdentity.CognitoUser(userData);
};

export const activateRefreshToken = async (username, callback = null) => {
  const refreshToken = localStorage.getItem('refresh_token');
  const authenticationDetails = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: refreshToken
  });
  const cognitoUser = CognitoUserFactory(username);

  await cognitoUser.refreshSession(authenticationDetails, (err, result) => {
    if (err) throw err;
    const accessToken = result.getAccessToken().getJwtToken();
    const refreshToken = result.getRefreshToken().getToken();
    const expiration = result.getAccessToken().getExpiration();

    if (callback) {
      callback(expiration);
    }
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
  });
};
