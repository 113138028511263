import { Surface, Symbols } from 'recharts';

export default function PieTypeChartLegend({ payload, type, typeList, setType, setTypeId, isSm }) {
  const onClickList = (event, index) => {
    if (type !== typeList[index].name) {
      setTypeId(typeList[index].specie_id);
      setType(typeList[index].name);
    } else {
      setTypeId('All');
      setType('All types');
    }
  };

  return (
    <span>
      <ul
        style={{
          listStyle: 'none'
        }}
      >
        {payload.map((entry, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <li
            key={`item-${index}`}
            onClick={(event) => onClickList(event, index)}
            style={{
              fontWeight: type === entry.value ? 'bold' : 'normal',
              display: isSm && 'inline-block',
              marginRight: '5px'
            }}
          >
            <Surface width={10} height={10} viewBox="0 0 10 10" style={{ marginRight: '5px' }}>
              <Symbols
                cx={5}
                cy={5}
                type="circle"
                size={100}
                fill={
                  type === entry.value
                    ? `${entry.color.substring(0, entry.color.length - 6)})`
                    : entry.color
                }
              />
            </Surface>
            <span>
              {entry.value} {(Number(entry.payload.percent) * 100).toFixed(2)}%
            </span>
          </li>
        ))}
      </ul>
    </span>
  );
}
