import {
  styled,
  Button,
  StepLabel,
  Step,
  Stepper,
  Box,
  Grid,
  MenuItem,
  Paper,
  Stack,
  StepConnector,
  stepConnectorClasses,
  TextField,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  FormControl
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faker } from '@faker-js/faker';
import Iconify from 'components/Iconify';
import { ApiGetLocations, ApiGetPresignedUpload, ApiNewUploadId } from 'api';
import { fDateTime } from 'utils/formatTime';
import { bytesToSize } from 'utils/formatSize';
import { UploadFileIcon, VideoFileIcon } from 'components/icon/DashboardIcon';
import { LoadingButton } from '@mui/lab';
import NewLocationModal from './NewLocationModal';
import VideoUploadArea from './VideoUploadArea';
import { setNewVideoFile } from './videoUploadSlice';

const columns = [
  { id: 'name', label: 'Location name', minWidth: 150 },
  {
    id: 'createdAt',
    label: 'Create date',
    minWidth: 120
  }
];

const steps = ['Add information', 'Upload video'];

export default function HorizontalLinearStepper({ setTab, setTitle, setUploadQueue }) {
  const [filesUpload, setFilesUpload] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [rows, setRows] = useState([]);
  const [location, setLocation] = useState('');
  const [locationName, setLocationName] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [loading, setLoading] = useState(true);
  const [uploadId, setUploadId] = useState('');
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  const handleStep = () => {
    if (location) {
      setActiveStep((prev) => (prev === 0 ? 1 : 0));
      ApiNewUploadId({ locationId: location }).then((response) => {
        setUploadId(response.data.id);
      });
    }
  };

  const handleLocation = (ev) => {
    setLocation(ev.target.value);
    setLocationName(rows[rows.findIndex((x) => ev.target.value === x.id)].name);
  };

  const handleDeleteFileUpload = (ev) => {
    const deleteValue = ev.target.getAttribute('name');
    setFilesUpload(filesUpload.filter((item) => item.name !== deleteValue));
  };

  const handleUploadFile = async () => {
    setTab('Loading');
    setTitle('Loading');
    setUploading(true);
    for (let i = 0; i < filesUpload.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop

      const fileId = faker.datatype.uuid();
      const fileObject = {
        id: fileId,
        name: filesUpload[i].name,
        location: locationName,
        date: fDateTime(new Date()),
        progress: 0,
        status: 'pending',
        path: 'videos',
        uploadId,
        filename: filesUpload[i].name
      };
      dispatch(setNewVideoFile(fileObject));

      setUploadQueue((prevState) => [
        ...prevState,
        {
          id: fileId,
          data: filesUpload[i],
          controller: new AbortController(),
          path: 'videos',
          uploadId,
          filename: filesUpload[i].name
        }
      ]);
    }
    setFilesUpload([]);
    setUploading(false);
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 4,
      border: 0,
      backgroundColor: 'rgba(145, 158, 171, 0.24)',
      borderRadius: 1,
      marginLeft: '10px',
      marginRight: '10px'
    }
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: 'rgba(145, 158, 171, 0.24)',
    zIndex: 1,
    color: '#fff',
    width: 48,
    height: 48,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: '#2AAAE2'
    }),
    ...(ownerState.completed && {
      backgroundColor: '#2AAAE2'
    })
  }));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    border: '1px solid rgba(145, 158, 171, 0.32)',
    borderRadius: '8px'
  }));

  useEffect(() => {
    ApiGetLocations()
      .then((response) => {
        setRows(
          response.data.data.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          })
        );
        setLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  // eslint-disable-next-line react/no-unstable-nested-components
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <Iconify icon="foundation:info" width={28} height={28} />,
      2: <UploadFileIcon width={24} color={activeStep === 0 ? '#637381' : '#fff'} />
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Box sx={{ width: '100%', my: 7 }}>
      {loading ? (
        <Stack direction="row" sx={{ mt: 20, justifyContent: 'center' }}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label, index) => (
              <Step key={label}>
                {/* eslint-disable-next-line react/jsx-no-bind */}
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  <Typography color="inherit" sx={{ mr: 1 }} value={index}>
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Card sx={{ boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
            <CardContent>
              {activeStep === 0 && (
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 10 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <FormControl fullWidth variant="standard">
                            <TextField
                              select
                              label="Location"
                              required
                              value={location}
                              onChange={handleLocation}
                              sx={{ minWidth: '300px' }}
                              SelectProps={{
                                MenuProps: {
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left'
                                  },
                                  PaperProps: {
                                    sx: { maxHeight: 200 }
                                  }
                                }
                              }}
                            >
                              {rows.map((item) => (
                                <MenuItem key={item.id} value={item.id} name={item.name}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                          <Box sx={{ py: 2, textAlign: 'right' }}>
                            <NewLocationModal
                              rows={rows}
                              setRows={setRows}
                              newLocation={newLocation}
                              setNewLocation={setNewLocation}
                              setLocation={setLocation}
                              columns={columns}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Button
                          variant="contained"
                          disabled={!location}
                          onClick={handleStep}
                          sx={{ mt: 5 }}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {activeStep === 1 && (
                <>
                  <Stack spacing={2}>
                    <VideoUploadArea
                      filesUpload={filesUpload}
                      setFilesUpload={setFilesUpload}
                      location={location}
                    />
                    {filesUpload.map((file, index) => (
                      <Item key={index}>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={11}>
                            <Stack direction="row" spacing={2}>
                              <Stack direction="column" justifyContent="center" alignItems="center">
                                <VideoFileIcon width={40} height={40} color="#292D32" />
                              </Stack>
                              <Stack direction="column">
                                <Typography>{file.name}</Typography>
                                <Typography>{bytesToSize(file.size)}</Typography>
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item xs={1}>
                            <Box display="flex" justifyContent="flex-end">
                              <Iconify
                                icon="iconoir:cancel"
                                sx={{ cursor: 'pointer' }}
                                name={file.name}
                                onClick={handleDeleteFileUpload}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Item>
                    ))}
                  </Stack>
                  <Grid container spacing={2} sx={{ mt: 5 }}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Button variant="text" sx={{ mr: 2 }} onClick={handleStep}>
                          Back
                        </Button>
                        <LoadingButton
                          loading={uploading}
                          variant="contained"
                          onClick={handleUploadFile}
                          sx={{ textTransform: 'unset' }}
                        >
                          Upload files
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );
}
