// material
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
// layouts
import Page from 'components/Page';
import AuthLayout from 'layouts/AuthLayout';
// components
import { ForgotPasswordForm } from 'sections/authentication/forgotPassword';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  return (
    <RootStyle title="ForgotPassword | Aquatic monitoring system">
      <AuthLayout />

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Forgot your password?
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Please enter the email address associated with your account, and we'll email you a
              link to reset your password.
            </Typography>
          </Box>

          <ForgotPasswordForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
