export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
};

export function pluralize(count, noun, suffix = 's') {
  return `${noun}${count > 1 ? suffix : ''}`;
}
