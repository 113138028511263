import { useState } from 'react';

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import Iconify from 'components/Iconify';

const columns = [
  { id: 'name', label: 'Name', minWidth: 200 },
  {
    id: 'date',
    label: 'Start date / time',
    minWidth: 120
  },
  {
    id: 'number',
    label: 'Number of fish'
  }
];

function createData(name, date, number) {
  return { name, date, number };
}

const rows = [
  createData('Gold Fish', '29 Jun 2021 07:24PM', 690),
  createData('Gold Fish', '29 Jun 2021 07:24PM', 690),
  createData('Gold Fish', '29 Jun 2021 07:24PM', 690),
  createData('Gold Fish', '29 Jun 2021 07:24PM', 690),
  createData('Gold Fish', '29 Jun 2021 07:24PM', 690),
  createData('Gold Fish', '29 Jun 2021 07:24PM', 690),
  createData('Gold Fish', '29 Jun 2021 07:24PM', 690),
  createData('Gold Fish', '29 Jun 2021 07:24PM', 690),
  createData('Gold Fish', '29 Jun 2021 07:24PM', 690),
  createData('Gold Fish', '29 Jun 2021 07:24PM', 690),
  createData('Gold Fish', '29 Jun 2021 07:24PM', 690),
  createData('Gold Fish', '29 Jun 2021 07:24PM', 690)
];

export default function Types() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column, index) => (
                <TableCell key={index} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, ind) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={ind}>
                <TableCell>
                  <img
                    src="https://www.liveaquaria.com/images/categories/large/lg_39507_Fantail_Goldfish_Red.jpg"
                    alt="fish"
                    width={40}
                    height={40}
                    style={{ borderRadius: '20%' }}
                  />
                </TableCell>
                {columns.map((column, index) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={index} align={column.align}>
                      {value}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <Button variant="outlined" size="small">
                    Edit
                  </Button>
                </TableCell>
                <TableCell>
                  <Iconify icon="ri:delete-bin-fill" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
