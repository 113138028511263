import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {
  CalendarIcon,
  ExportIcon,
  EyeIcon,
  FilterIcon,
  FishFilterIcon,
  LocationIcon,
  TimeFilterIcon
} from 'components/icon/DashboardIcon';
import BasicDateRangePicker from 'components/BasicDateRangePicker';
import BasicMenu from 'components/BasicMenu';
import { useEffect, useState } from 'react';
import { ApiGetFilterAvailableData, ApiGetReportFile } from 'api';
import { saveAs } from 'file-saver';
import { fDateDYmdToDDmy, fDateSDmyToDYmd } from 'utils/formatTime';
import Config from 'config';

const BoxStyle = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
});

export default function FilterBar(props) {
  const {
    type,
    typeId,
    setType,
    setTypeId,
    location,
    locationId,
    setLocation,
    setLocationId,
    confidence,
    confidenceId,
    setConfidence,
    setConfidenceId,
    isFixed,
    title,
    setTitle,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    queryFullParam,
    isZero,
    time,
    timeId,
    setTime,
    setTimeId
  } = props;
  const user = useSelector((state) => state.userSlice);
  const userGroup = user.group[0];

  const [showFilter, setShowFilter] = useState(true);
  const [typeItems, setTypeItems] = useState([{ name: 'All types', id: 'All' }]);
  const [locationItems, setLocationItems] = useState([{ name: 'All locations', id: 'All' }]);
  const [confidenceItems, setConfidenceItems] = useState([
    { name: 'All confidences', id: 'Any' },
    { name: '95%+', id: '0.95' },
    { name: '90%+', id: '0.9' },
    { name: '85%+', id: '0.85' }
  ]);

  const [timeItems, setTimeItems] = useState([
    { name: 'Any time', id: 'Any' },
    { name: '00.01 - 6.00', id: '00:01-06:00' },
    { name: '6.01 - 12.00', id: '06:01-12:00' },
    { name: '12.01 - 18.00', id: '12:01-18:00' },
    { name: '18.01 - 00.00', id: '18:01-00:00' }
  ]);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  const handleLoadingReportClose = () => setIsLoadingReport(false);
  const handleLoadingReportOpen = () => setIsLoadingReport(true);

  const MySwal = withReactContent(Swal);

  const handleExport = () => {
    handleLoadingReportOpen();
    ApiGetReportFile(queryFullParam)
      .then((response) => {
        if (response.data) {
          const urlParams = new URLSearchParams(queryFullParam);
          const dateFrom = urlParams.get('dateFrom');
          const dateTo = urlParams.get('dateTo');
          const filename = `Report_${fDateDYmdToDDmy(dateFrom)}_to_${fDateDYmdToDDmy(dateTo)}.csv`;
          const blob = new Blob([`\uFEFF${response.data}`], { type: 'text/csv;charset=utf-8' });
          saveAs(blob, filename);
        } else {
          MySwal.fire({ icon: 'error', text: 'No data to export' });
        }
        handleLoadingReportClose();
      })
      .catch((err) => {
        handleLoadingReportClose();
        MySwal.fire({ icon: 'error', text: err.message });
      });
  };

  const handleShow = () => {
    setShowFilter((prev) => !prev);
  };

  useEffect(async () => {
    const query = `?dateFrom=${fDateSDmyToDYmd(startDate)}&dateTo=${fDateSDmyToDYmd(endDate)}`;
    const response = await ApiGetFilterAvailableData(query).catch((error) => console.error(error));
    const species = response.data.data.specie;
    setTypeItems([{ name: 'All types', id: 'All' }].concat(species));
    setType('All types');
    setTypeId('All');
    const locations = response.data.data.location;
    setLocationItems([{ name: 'All locations', id: 'All' }].concat(locations));
    setLocation('All locations');
    setLocationId('All');
    const confidences = response.data.data.confidence;
    setConfidenceItems([{ name: 'All confidences', id: 'Any' }].concat(confidences));
    setConfidence('All confidences');
    setConfidenceId('Any');
    setTime('Any time');
    setTimeId('Any');
  }, [startDate, endDate]);

  useEffect(() => {
    let query = `?dateFrom=${fDateSDmyToDYmd(startDate)}&dateTo=${fDateSDmyToDYmd(endDate)}`;
    query = typeId === 'All' || typeId === undefined ? query : `${query}&type=${typeId}`;
    query =
      locationId === 'All' || locationId === undefined ? query : `${query}&location=${locationId}`;
    query =
      confidenceId === 'Any' || confidenceId === undefined
        ? query
        : `${query}&confidence=${confidenceId}`;

    query = timeId === 'Any' || timeId === undefined ? query : `${query}&time=${timeId}`;

    ApiGetFilterAvailableData(query)
      .then((response) => {
        const species = response.data.data.specie;
        setTypeItems([{ name: 'All types', id: 'All' }].concat(species));
        const locations = response.data.data.location;
        setLocationItems([{ name: 'All locations', id: 'All' }].concat(locations));
        const confidences = response.data.data.confidence;
        setConfidenceItems([{ name: 'All confidences', id: 'Any' }].concat(confidences));
      })
      .catch((error) => console.error(error));
  }, [type, location, confidence]);

  useEffect(() => {
    setTitle(type);
    if (type === 'All types') {
      setTitle('Dashboard');
    }
  }, [type]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        <Box>
          <Typography variant="h3">{title}</Typography>
        </Box>
        {!isFixed ? (
          <Button
            variant="outlined"
            startIcon={<EyeIcon width={20} />}
            sx={{ textTransform: 'unset !important' }}
            onClick={() => {
              window.open(Config.getValue('REACT_APP_IFRAME_URL'), '_blank');
            }}
          >
            Ocean data platform view
          </Button>
        ) : (
          <Grid sx={{ cursor: 'pointer' }} onClick={handleShow}>
            <FilterIcon width={40} height={50} />
          </Grid>
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        width={!isFixed ? 'none' : 'calc(100% - 90px)'}
        sx={{
          mb: 5,
          backgroundColor: `${!isFixed && '#F9FAFB'}`,
          display: `${(function () {
            if (!showFilter && isFixed) {
              return 'none';
            }
          })()}`
        }}
      >
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <BasicDateRangePicker
            icon={<CalendarIcon width={20} />}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />

          <BasicMenu
            topic={time}
            icon={<TimeFilterIcon width={20} />}
            items={timeItems}
            setItem={setTime}
            setItemId={setTimeId}
          />
          <BasicMenu
            topic={type}
            icon={<FishFilterIcon width={20} />}
            items={typeItems}
            setItem={setType}
            setItemId={setTypeId}
          />
          <BasicMenu
            topic={location}
            icon={<LocationIcon width={20} />}
            items={locationItems}
            setItem={setLocation}
            setItemId={setLocationId}
          />

          <BasicMenu
            topic={confidence}
            icon={<FilterIcon width={20} />}
            items={confidenceItems}
            setItem={setConfidence}
            setItemId={setConfidenceId}
          />
        </Stack>
        {userGroup.includes('Master') && (
          <Stack>
            <Button
              variant="text"
              color="greytone"
              onClick={handleExport}
              startIcon={<ExportIcon width={20} />}
              sx={{ my: 1 }}
              disabled={isZero}
            >
              Export
            </Button>
          </Stack>
        )}
      </Stack>
      <Modal open={isLoadingReport} disableEscapeKeyDown>
        <BoxStyle>
          <CircularProgress />
        </BoxStyle>
      </Modal>
    </>
  );
}
