import { Paper, Typography, styled } from '@mui/material';
import { UploadFileIcon } from 'components/icon/DashboardIcon';
import MyDropzone from './MyDropzone';

export default function VideoUploadArea({ filesUpload, setFilesUpload, location }) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#F9FAFB',
    ...theme.typography.body2,
    padding: theme.spacing(5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    borderStyle: 'dashed',
    borderWidth: '1px',
    fontFamily: 'Plus Jakarta Sans'
  }));

  return (
    <Item sx={{ my: 5 }}>
      <MyDropzone filesUpload={filesUpload} setFilesUpload={setFilesUpload} location={location}>
        <UploadFileIcon width={95} height={95} color="rgba(0, 0, 0, 0.08)" />
        <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
          Drop files here or click{' '}
          <span style={{ color: '#2AAAE2', textDecoration: 'underline' }}>browse</span> through your
          machine
        </Typography>
        <Typography variant="h10">
          Maximum upload file size : 5 GB./ Only .mp4 format<span style={{ color: 'red' }}>**</span>
        </Typography>
      </MyDropzone>
    </Item>
  );
}
