import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateInverse(year, month, date) {
  return format(new Date(year, month, date), 'yyyy-MM-dd');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

export function fDateOnly(date) {
  const dateSplit = date.split(' ');
  let response = dateSplit[0];
  if (dateSplit.length === 1 && response.search('/') === -1) {
    response = format(new Date(date), 'd/M/yyyy');
  }
  return response;
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fDateSDmyToDYmd(date) {
  const dateSplit = date.replace(/\s/g, '').split('/');
  return fDateInverse(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
}

export function fDateDYmdToDDmy(date) {
  return format(new Date(date), 'dd-MM-yyyy');
}

export function fDateTimeNoMillisec(date) {
  if (date) {
    const split = date.split(' ');
    const datePart = split[0].split('/');
    const timePart = split[1].split(':');
    return format(
      new Date(datePart[2], datePart[1] - 1, datePart[0], timePart[0], timePart[1], timePart[2]),
      'dd/MM/yyyy HH:mm'
    );
  }

  return date;
}
